// I call like this
//
// ---------- 0
// MOBILE SMALL
export const MOBILE_SMALL_BP = 340;
// MOBILE
export const MOBILE_BP = 640;
// TABLET
export const TABLET_BP = 1024;
// DESKTOP
export const DESKTOP_BP = 1280;
// DESKTOP LARGE
// ---------- infinity

export const MOBILE_SMALL_MAX = `${MOBILE_SMALL_BP}px`;
export const MOBILE_SMALL_MIN = `${MOBILE_SMALL_BP + 1}px`;
export const MOBILE_MAX = `${MOBILE_BP}px`;
export const MOBILE_MIN = `${MOBILE_BP + 1}px`;
export const TABLET_MAX = `${TABLET_BP}px`;
export const TABLET_MIN = `${TABLET_BP + 1}px`;
export const DESKTOP_MAX = `${DESKTOP_BP}px`;
export const DESKTOP_MIN = `${DESKTOP_BP + 1}px`;
