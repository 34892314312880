import * as React from "react";
import styled from "@emotion/styled";

const Label = styled("label")`
  display: block;
`;

const LabelText = styled("span")`
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled("input")`
  width: 100%;
  font-size: 18px;
  outline: none;
  padding: 12px 20px;
  border-radius: 5px;
  font-weight: 700;
  border: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  -webkit-appearance: none;
`;

export default ({
  className,
  label,
  value,
  placeholder,
  onChange,
  ...inputProps
}) => (
  <Label className={className}>
    <LabelText>{label}</LabelText>
    <Input
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      {...inputProps}
    />
  </Label>
);
