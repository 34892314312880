import * as React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import Button from "../common/Button";
import Input from "../common/Input";
import FaIcon from "../common/FaIcon";
import Checkbox from "../common/Checkbox";
import Markdown from "../common/Markdown";

const SxForm = styled("form")`
  max-width: 500px;
  margin: 20px auto;
`;

const BackBtn = styled(Button)`
  padding: 5px 10px;
`;

const SxButton = styled(Button)`
  margin-top: 20px;
`;

const Large = styled("p")`
  font-size: 20px;
`;

const SxCheckbox = styled(Checkbox)`
  margin-top: 20px;
`;

const Disclaimer = styled("div")`
  font-size: 14px;
  margin: 20px 0;
`;

export default connect(st => ({
  vals: st.vals,
  brainstormBoxVal: st.brainstormBox.val
}))(
  withTranslation()(({ t, vals, brainstormBoxVal, onSubmit, onBack }) => {
    const [email, setEmail] = React.useState("");
    const [checked, setChecked] = React.useState(false);

    return (
      <SxForm
        onSubmit={e => {
          e.preventDefault();
          onSubmit(email, vals, brainstormBoxVal);
        }}
      >
        <BackBtn type="button" onClick={onBack}>
          <FaIcon icon="arrow-left" />
        </BackBtn>
        <Large>{t("emailSubmit.inProgress")}</Large>
        <Large>{t("emailSubmit.prompt")}</Large>
        <Input
          type="email"
          primary
          value={email}
          placeholder={t("emailSubmit.placeholder")}
          onChange={e => setEmail(e.target.value)}
        />
        <Disclaimer>
          <Markdown text={t("emailSubmit.disclaimer")} />
        </Disclaimer>
        <SxCheckbox
          value={checked}
          onChange={setChecked}
          label={t("emailSubmit.checkmark")}
        />
        <SxButton primary type="submit" disabled={!checked || !email}>
          {t("emailSubmit.submit")}
        </SxButton>
      </SxForm>
    );
  })
);
