import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import { keyframes } from "@emotion/core";
import { Switch, Route } from "react-router-dom";

import Headline from "../common/Headline";
import Input from "../common/Input";
import Frame from "../common/Frame";
import FaIcon from "../common/FaIcon";
import Button from "../common/Button";

import Flower from "./Flower";
import ProgressBar from "./ProgressBar";
import LevelConnect from "./LevelConnect";

import { postIkigai } from "../api/requests";

import { STEPS_TO_LVL_2, POST_ITS_COUNT, CIRCLES_COUNT } from "../config";

const SxFrame = styled(Frame)`
  max-width: 400px;
  margin: 0 auto;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
`;

const SxHeadline = styled(Headline)`
  margin: 0;
  text-align: left;
`;
const Left = styled("div")``;

const Sub = styled("h2")`
  margin: 5px 0 0;
  color: #888;
  font-size: 20px;
  line-height: 1.1;
`;

const Group = styled("div")`
  width: 100%;
`;

const SxButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const BunchHead = styled("h3")`
  color: #666;
  margin: 0;
`;

const Bunch = styled("div")`
  margin: 10px 0;
  padding: 10px 20px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
`;

const Q = styled("p")`
  margin: 10px 0;
  color: #666;
  line-height: 1.3;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SxFlower = styled(Flower)`
  position: absolute;
  z-index: -1;
  top: 0;
`;

const appear = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Dialog = styled("div")`
  width: 100%;
  animation: ${appear} 0.2s ease forwards;
`;

const IntroContainer = styled("div")`
  width: 100%;
  padding: 20px 0;
`;

const Intro = styled(BunchHead)`
  font-weight: 400;
`;

const Prompt = styled(BunchHead)`
  margin-top: 20px;
`;

// Step = Circle
// Interval size = num post its from level 1
function getSlices(array, step, maxSteps, intervalSize) {
  const max = maxSteps * intervalSize;

  const from1 = step * intervalSize;
  const to1 = (step + 1) * intervalSize - 1;

  const from2 = (from1 + intervalSize) % max;
  const to2 = (to1 + intervalSize) % max;

  return {
    slice0: array.slice(from1, to1 + 1),
    slice1: array.slice(from2, to2 + 1)
  };
}

const getConfig = (intersection, isIntro) => {
  if (isIntro) {
    return {
      mx: 0,
      my: 50,
      mShowL: true,
      mShowB: true,
      mShowR: true,
      mShowT: true,
      mSpace: 250,
      mRad: 500,
      mBorder: 7,
      S: 12
    };
  }

  switch (intersection) {
    case 0:
      return {
        mx: 150,
        my: 150,
        mShowT: true,
        mShowL: true,
        mShowB: false,
        mShowR: false
      };
    case 1:
      return {
        mx: 150,
        my: -150,
        mShowT: false,
        mShowL: true,
        mShowB: true,
        mShowR: false
      };
    case 2:
      return {
        mx: -150,
        my: -150,
        mShowT: false,
        mShowL: false,
        mShowB: true,
        mShowR: true
      };
    case 3:
      return {
        mx: -150,
        my: 150,
        mShowT: true,
        mShowL: false,
        mShowB: false,
        mShowR: true
      };
    default:
      return {};
  }
};

export default LevelConnect(
  withTranslation()(({ t, history, location, vals, setVals }) => {
    const params = new URLSearchParams(location.search);
    const step = parseInt(params.get("s"));
    const idx = STEPS_TO_LVL_2 + step;

    const [val, setVal] = React.useState(vals[idx] || "");

    const isIntro = location.pathname.includes("intro");
    const flowerConf = getConfig(step, isIntro);
    const text =
      step < CIRCLES_COUNT
        ? t(`flower.B${step + 1}.name`)
        : t("sentencesComplete");

    const slices = getSlices(vals, step, CIRCLES_COUNT, POST_ITS_COUNT);

    React.useEffect(() => {
      setVal(vals[idx] || "");
    }, [vals, idx]);

    return (
      <SxFrame>
        <Switch>
          <Route path="/level2/intro">
            <IntroContainer>
              <SxHeadline>Level 2</SxHeadline>
              <Sub>{t(`flower.B.sub`)}</Sub>
            </IntroContainer>
            <Bunch>
              <Intro>{t("flower.B.description")}</Intro>
              <Prompt>{t("flower.B.prompt")}</Prompt>
            </Bunch>
            <SxButton
              onClick={() => {
                const nextParams = new URLSearchParams(location.search);

                history.push(`/level2?${nextParams}`);
              }}
            >
              {t("letsgo")}
            </SxButton>
          </Route>
          <Route>
            <Row>
              <Left>
                <SxHeadline>{text}</SxHeadline>
                <Sub>{t("formCommonSentence")}</Sub>
              </Left>
            </Row>
            <Dialog>
              <ProgressBar value={step} max={CIRCLES_COUNT} />
              <Bunch>
                <BunchHead>{t(`flower.A${step + 1}.name`)}</BunchHead>
                {slices.slice0.map((text, i) => (
                  <Q key={text + i}>{text}</Q>
                ))}
              </Bunch>
              <Bunch>
                <BunchHead>
                  {t(`flower.A${((step + 1) % CIRCLES_COUNT) + 1}.name`)}
                </BunchHead>
                {slices.slice1.map((text, i) => (
                  <Q key={text + i}>{text}</Q>
                ))}
              </Bunch>
              <Group>
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    setVals(val, STEPS_TO_LVL_2 + step);
                    setVal("");

                    const nextStep = step + 1;
                    const nextParams = new URLSearchParams(location.search);

                    if (nextStep >= CIRCLES_COUNT) {
                      postIkigai(params.get("n"), vals);

                      nextParams.set("l", 2);

                      history.push(`/progress?${nextParams}`);
                    } else {
                      nextParams.set("s", nextStep);

                      history.push(`/level2?${nextParams}`);
                    }
                  }}
                >
                  <Input
                    placeholder={t("sentencePlaceholder")}
                    value={val}
                    onChange={e => setVal(e.target.value)}
                  />
                  <SxButton primary={true} type="submit" disabled={!val}>
                    Weiter <FaIcon primary icon="arrow-right" />
                  </SxButton>
                </form>
              </Group>
            </Dialog>
          </Route>
        </Switch>
        <SxFlower
          mSpace={300}
          mRad={800}
          mBorder={25}
          {...flowerConf}
          noLabels
        />
      </SxFrame>
    );
  })
);
