import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Cache from "i18next-localstorage-cache";

i18n
  .use(initReactI18next)
  .use(Cache)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "en"
  });

//Version: EN
//landing page theGarden
i18n.addResourceBundle("en", "translation", {
  landing: {
    tagline: "Find space to transform",
    aboveHeadline: "Welcome to",
    headline: "theGarden",
    headLeft: "Opening a new holistic space in the digital world.",

    //Spalte links
    descriptionLeft: [
      "theGarden is a harbor for the visionaries and creatives, the entrepreneurs and explorers, the impact makers. For those who soar above the norm and challenge the status quo. A vessel to collectively envision and engage in building a regenerative and peaceful future. To sustain life on our home planet. ",
      "In theGarden you will find tools, paths and companions for **personal growth and cooperative development**. Designed to activate our inner creativity and facilitate transformation.",
      "Take your time and slow down. Breathe in and enter.",
      "Start with our first tool: Ikigai - activating your purpose."
    ],

    //Spalte rechts
    headRight: "IKIGAI - activating your purpose.",
    descriptionRight: [
      "Ikigai is an old Japanese concept. Freely translated to the **source of value in your life**. In modern terms, the purpose or reason that makes you stand up every morning with a smile to embrace life.",
      "Activating your Ikigai, your inner well, creates a personal drive. You become powerful from the inside out. Find the energy you need to plant, water and grow what is important for you in this world! Realize your part in the puzzle.",
      "We created a first path in theGarden to accompany you in your process. Start into your purpose journey."
    ],
    //einfach: descriptionTiny ?
    descriptionRightTiny: [
      "Credits for a modern Ikigai version go to [Anaïs Bock](https://www.letsworkmagic.com) and [Iris von Tiedemann](https://www.transformational-leadership.de)."
    ],
    start: "Start IKIGAI",

    //Gedicht
    poem: [
      "theGarden",
      "envisions a space",
      "renewed possibility and transition",
      "space for regeneration",
      "in the spirit of a calm and quiet zen Garden",
      "a gap emerges, an in-between, a tranquil silence",
      "to reflect, to gain insight, to reconnect",
      "for our minds and hearts to grow",
      "for us to find new ways."
    ]
  },

  //Infos IKIGAI
  intro: {
    headline: "Inner monologue ...",
    name: "Intro",
    title: "Discover purpose.",
    subtitle: "Develop your IKIGAI.",
    //headlineHow: "How does it work?",
    descriptionHow: [
      "**How does it work?**",
      "We've created this tool based on the Ikigai process known from transformation work and coaching. To guide you on your discovery, the Ikigai tool is built like a path that leads you one layer deeper with every level, towards the core. At the innermost point you find your **purpose sentence**.",
      "The journey allows for a deep transformation and unfolds at its own pace. Bring time and open up to the process. The progress screen shows you an overview of your process and the activities you've entered. Click on the symbol in the upper left corner.",
      "You can also sit down with a good friend. Discover your Ikigais together, reflect and mirror yourselves. Get in touch to what is inside you and a common space of evolving can emerge.",
      "You can rest any time on the way and come back to where you stopped. All data is saved in your browser.",
      "Have fun and let's go!"
    ],
    //headlineWhat: "What is IKIGAI?",
    descriptionWhat: [
      "**What is IKIGAI?**",
      "Ikigai is a Japanese concept that could be freely translated as **the source of value in your life**. Or in modern terms, the purpose or reason that makes you stand up every morning with a smile to embrace life.",
      "Your Ikigai reflects the jewel inside your self. Embodying and expressing it in your action creates a mental state in which you feel at ease, in the right place, or in tune with yourself.",
      "Activities that flow with your Ikigai are often spontaneous and without effort (passion X competence) and accompanied by the experience of satisfaction and meaning (credit X world). The Ikigai tool is created for you to explore the intersection of all these four areas as you move deeper layer by layer.",
      "We believe that connecting yourself to your Ikigai, to your inner source, creates the drive and energy you need. To become powerful from the inside out, to organically and courageously grow what is important for you in this world."
    ],
    descriptionTiny: [
      "The tool is free for your personal use. We're taking privacy seriously. All data you enter remains within your local browser.",
      // Doesn't really fit here because we're saying all data remains in your browser:
      // We're taking care of data privacy and secure storage, currently running on German servers, encryption to come in the future.
      "Reference and credits: [Anaïs Bock](https://www.letsworkmagic.com), [Iris von Tiedemann](https://www.transformational-leadership.de), [Dan Buettner](https://www.ted.com/talks/dan_buettner_how_to_live_to_be_100)"
    ]
  },

  //Interaktionen
  finished: {
    thanks: "We're happy that you are here!",
    cooperation: "This project is a cooperation between"
  },

  expert: {
    headline: "Speak with an expert about your results",
    bulletPoints: [
      "30 minutes call with a transformation facilitator",
      "Get a second view on your Ikigai process and outcome",
      "Discuss the meaning and integration to your life"
    ],
    submit: "Book now (45 €)",
    success: "Please confirm your email address. We'll get in touch with you!"
  },

  package: {
    headline: "Receive your personal Ikigai package",
    bulletPoints: [
      "Poster with your personal Ikigai sentence via email",
      "Personal slide deck with all images and texts of your process",
      "Transformation script with further information on IKIGAI context and use"
    ],
    submit: "Buy now (15 €)",
    success: "Please confirm your email address. We'll get in touch with you!"
  },

  emailSubmit: {
    inProgress: "Automatic payment is still in progress.",
    prompt:
      "When you enter your email address, we will get back to you with further details.",
    placeholder: "mail@example.com",
    disclaimer:
      "**Note:** To proceed from here, we have to store your **email address**, your **Ikigai** and the content of your **Brainstorm notes** on our servers.",
    checkmark: "I agree that my information will be stored.",
    submit: "Send now"
  },

  error: {
    generic: "That didn't work. Please try again."
  },

  success: {
    generic: "🌱 Success."
  },

  confirm: {
    thanks: "Your email address has been successfully confirmed!",
    contactYou: "We will contact you about the next steps."
  },

  //Buttons
  ikigai: "IKIGAI",
  purposeTool: " – Purpose Tool",
  progress: "Progress",
  whoMadeThis: "Who's behind this?",
  language: "Language: ",
  done: "Done",
  resetAll: "Reset all entries. Restart from the beginning.",
  areYouSure:
    "You can also edit your answers by clicking on the headlines! If you click confirm, all data you entered will be deleted. Are you sure?",
  skip: "Skip intro (don't!)",
  print: "Print (PDF)",
  back: "Back",
  create: "Create my IKIGAI",
  letsgo: "Let's go",
  howIsIkigai: "What is IKIGAI?",
  whatIsIkigai: "Where does IKIGAI come from?",
  teamCheckin: "Create a team IKIGAI",
  or: "Or",
  check: "Check my IKIGAI",
  postIt: "Activity",
  next: "Next",
  nextLevel: "Level {{level}}",
  youDidIt: "Level taken!",
  //clean code: Text rausnehmen?
  circlesFilled: "All circles filled!",
  fillTheCircles: "Fill the circles",
  reallyStrong: "You're going strong!",
  formCommonSentence: "Combine into one sentence",
  combineSentences: "Combine into one sentence",
  overview: "Full screen",
  sentencesComplete: "Great sentences!",
  finishedWellDone: "Well done.",
  ikigaiComplete: "IKIGAI complete!",
  sentencePlaceholder: "I / We ...",

  //Inhalt IKIGAI
  monolog: [
    "I look into our world.",
    "Climate catastrophies, political conflicts, crisis around food, water, shelter. Exploitation of people and nature. The world is out of balance.",
    "I sense the urge to act. I feel tension to spend my life with work I cannot find a purpose for.",
    "I want to create something new that speaks from my soul, something worthwhile, valuable, joyful.",
    "Still I feel powerless, sometimes numb.",
    "I am searching to connect to my self, to harness my inner power. I want to become aware. 'Know thyself', the Greek philosophers said.",
    "How can I have an impact on the things I care about?",
    "How do I connect my strengths, my passions, my purpose to realize what I am supposed to do in this world?"
  ],

  result: {
    welcome: "Welcome to your progress page",
    welcomeSub:
      "The circles will fill with the activities and sentences that you enter as you travel. With each level you move one layer deeper towards the core. Move at your own pace and take your time.",
    done: "Congratulations! You just finished your IKIGAI.",
    doneSub: "We hope you discovered something new about yourself.",
    hint:
      "You can **edit** any area by clicking a headline.  \nEverything you enter is saved **in your browser**.  \nFeel free to close the tab and come back any time.",
    hintTiny: "It's all **private**. None of your notes leave your browser."
  },

  brainstormBox: {
    headline: "Brainstorm",
    prompt: "Brainstorm",
    sub:
      "A space for you to make notes. Open up to what freely comes to your mind when asking the questions. Focus on things you do, concrete activities. Simply start to note anything that resonates, collect keywords that feel about right. Then, choose three activities, go back and enter them into the fields.",
    placeholder: "Activity 1, Activity 2, ..."
  },

  flower: {
    A: {
      sub: "Fill your four circles",
      description:
        "The four circles in level 1 open up the space for introspection. Here you discover your passions, skills, ambitions, and your puzzle piece in the world. Find a calm setting, connect to yourself, and let the questions guide your way. Ikigai is about finding proper activities that express your purpose, so use verbs in your notes.",
      prompt:
        "You will find space to brainstorm. There you can freely write down anything that comes to your mind. In the end, enter the three activities that resonate most with you."
    },
    A1: {
      name: "Passion",
      description: "Remember your passions.",
      questions: [
        "Which activities bring you into flow?",
        "What gives you energy and joy?",
        "What do you love to do?"
      ]
    },
    A2: {
      name: "Strength",
      description: "Explore your powers.",
      questions: [
        "Which competencies and strengths did you develop?",
        "What did you become good at? What do you master?",
        "What can you do outstandingly well?"
      ]
    },
    A3: {
      name: "Credit",
      description: "Discover your trade.",
      questions: [
        "Which activity should earn you money?",
        "What do you want to receive reward for?",
        "What do you want to be appreciated for?"
      ]
    },
    A4: {
      name: "World",
      description:
        "Feel into the perspective of the world. Find out what is your part in the puzzle.",
      questions: [
        "What does the world most urgently wish for you to do?",
        "How can you make an impact for a prosperous world?",
        "What can you offer to serve?"
      ]
    },
    B: {
      sub: "Develop consistent sentences",
      description:
        "In level 2 your circles start to overlap. You get four new intersections. Scan for verbs that repeat when intersecting two circles each time. Find a sentence with a verb that contains the essence of both circles.",
      prompt:
        "Write sentences that start with 'I *VERB* ...'. Some examples: 'I design... for...', 'I develop... with...', 'I move... towards...', 'I do...'"
    },
    B1: {
      name: "Evolvement"
    },
    B2: {
      name: "Profession"
    },
    B3: {
      name: "Responsibility"
    },
    B4: {
      name: "Calling"
    },
    C: {
      sub: "Condense into new sentences",
      description:
        "With level 3 you move deeper into the process. Your sentences from level 2 are now intersecting two at a time. Extract the essence of the two sentences shown to you. Condense them into one coherent sentence. Pay attention to your intuition. You can do it!",
      prompt:
        "Breathe. Find calm. Write a new sentence that starts with 'I *VERB* ...'"
    },
    C1: {
      name: "Mission"
    },
    C2: {
      name: "Fulfillment"
    },
    C3: {
      name: "Service"
    },
    C4: {
      name: "Impact"
    },
    D: {
      name: "Reason for Being",
      description:
        "You've reached the core. Boil down your four last sentences into one common essence.  \nFind out about the nature of your Ikigai.",
      prompt:
        "Connect to your inner heart space by breathing into your chest. Speak out loud your last four sentences. You can repeat this for a couple of times. Open up to impulses and inspiration. Craft your final purpose sentence."
    }
  }
});

//Version: GE
//landing page
i18n.addResourceBundle("de", "translation", {
  landing: {
    tagline: "Raum für Transformation",
    aboveHeadline: "Willkommen bei",
    headline: "theGarden",
    headLeft: "Ein holistischer Kreativraum in der digitalen Welt.",

    //Spalte links
    descriptionLeft: [
      "theGarden bietet einen Hafen für Visionäre und Kreative, Unternehmer und Entdecker, Aktive und Veränderer. Schöpferische, die außerhalb der Norm segeln und den status quo herausfordern. Ein Vehikel für unbekanntes Gewässer, um gemeinsam neue Formen zu finden, wie wir uns engagieren und eine regenerative Zukunft aufbauen können.",
      "In theGarden findest du Werkzeuge, Wege und Begleiter für persönliches Wachstum und kooperative Entwicklung. Gestaltet um unsere innere Kreativität zu aktivieren und Transformation zu erleichtern. Um gemeinsam das Leben auf unserem Heimatplaneten zu erhalten.",
      "Pausiere und verlangsame. Atme durch und tritt ein. Starte mit unserem ersten Werkzeug: Ikigai - mache dich auf zur Wegfindung."
    ],

    //Spalte rechts
    headRight: "IKIGAI – ein Werkzeug zur Wegfindung",
    descriptionRight: [
      "Ikigai ist ein altes japanisches Konzept. Es bedeutet die **innere Quelle von Wert und Freude**. Frei übersetzt, der 'Lebenssinn' oder 'Lebensweg', der dich morgens mit einem Lächeln in den Tag starten lässt.",
      "Deinem Ikigai zu folgen bedeutet, dir deines inneren Antriebs bewusst zu werden und ihn in deinem Tun voll auszudrücken. Diese Verkörperung macht dich von innen nach außen kraftvoll. Finde den Elan, um den Samen zu pflanzen und zu wässern – für die Dinge, die dir in der Welt wichtig sind! Finde deinen Teil im großen Puzzle.",      
      "Wir haben einen ersten Weg in theGarden für den Ikigai Prozess gebaut. Starte in deine eigene Wegfindung."
    ],
    descriptionRightTiny: [
      "Inspiration für das moderne Ikigai von [Anaïs Bock](https://www.letsworkmagic.com) und [Iris von Tiedemann](https://www.transformational-leadership.de)."
    ],
    start: "IKIGAI starten",

    //Gedicht
    poem: [
      "theGarden",
      "visioniert einen raum",
      "erneuerte möglichkeit, übergang",
      "raum für regeneration",
      "im geiste eines ruhigen zen Gartens",
      "eine lücke entsteht, ein dazwischen",
      "eine friedliche stille",
      "zum nachdenken, erkennen, anbinden",
      "geist und herz wachsen",
      "wir finden neue wege."
    ]
  },

  //Infos IKIGAI
  intro: {
    headline: "Innerer Monolog ...",
    name: "Einleitung",
    title: "Finde deinen Weg.",
    subtitle: "Erkunde dein IKIGAI.",
    descriptionHow: [
      "**Wie funktioniert IKIGAI?**",
      "Das Ikigai Tool basiert auf dem Ikigai Prozess aus transformativer Arbeit und Coaching. Um dich auf deiner Entdeckungsreise zu begleiten, ist das Tool wie ein Weg gebaut, der dich mit jedem Level eine Schicht tiefer bringt bis zum inneren Kern. Am innersten Punkt findest du deinen **Purpose Satz** (engl. für Lebenssinn oder Lebensweg).",
      "Der Prozess ermöglicht eine tiefe Transformation und entfaltet sich in seinem eigenen Tempo. Bringe Zeit mit. Auf dem Fortschritt Screen siehst du einen Überblick über deinen Ikigai Prozess und deine eingetragenen Aktivitäten. Klicke dafür auf das Symbol in der oberen linken Ecke.",
      "Du kannst dich auch mit einer guten Freundin oder einem guten Freund zusammensetzen. Macht das Ikigai gemeinsam, tauscht euch aus, spiegelt euch und es entsteht ein gemeinsamer Raum der Entdeckung.",
      "Du kannst auf deinem Weg Pausen einlegen und jederzeit wieder an den Punkt zurückkommen, an dem du Rast gemacht hast.",
      "Viel Spaß und los geht's!"
    ],
    descriptionWhat: [
      "**Was ist IKIGAI?**",
      "Ikigai ist ein altes japanisches Konzept. Es bedeutet die **Quelle von Wert und Freude** in deinem Leben. Frei übersetzt, der 'Lebenssinn' oder 'Lebensweg', der dich morgens mit einem Lächeln in den Tag gehen lässt. Dein Ikigai in deinem Tun auszudrücken, kreiert einen mentalen Zustand von Leichtigkeit und Einklang.",
      "Aktivitäten, die deinem Ikigai entsprechen, gehen dir häufig spontan und ohne Mühe von der Hand (Passion X Kompetenz) und sind begleitet von einer erlebten Zufriedenheit und Bedeutung (Verdienst X Welt). Der Ikigai Prozess begleitet dich dabei, die Schnittmenge aus allen vier Bereichen zu erkunden, während du dich weiter nach innen bewegst.",
      "Dein Ikigai zu finden, bedeutet dir deiner inneren Kraftquelle bewusst zu werden. Dich damit zu verbinden, macht dich von innen heraus kraftvoll – um mutig und wirksam das wachsen zu lassen, was dir in der Welt wichtig ist."
    ],
    descriptionTiny: [
      "Das Tool ist für dich kostenlos. Uns ist Privatsphäre wichtig. Alle deine Daten bleiben bei dir privat im Browser.",
      "Quellen: [Anaïs Bock](www.letsworkmagic.com), [Iris von Tiedemann](www.transformational-leadership.de), [Dan Buettner](www.ted.com/talks/dan_buettner_how_to_live_to_be_100)"
    ]
  },

  //Interaktion
  finished: {
    thanks: "Wir freuen uns, dass du hier gelandet bist!",
    cooperation: "Dieses Projekt ist eine Kooperation von"
  },

  expert: {
    headline: "Sprich mit einem Experten über die Ergebnisse",
    bulletPoints: [
      "30 Minuten Call mit Transformations-Experte*in",
      "Zweite Meinung einholen zu deinem Ikigai Prozess und Ergebnis",
      "Bedeutung besprechen und in dein Leben integrieren"
    ],
    submit: "Jetzt buchen (45 €)",
    success: "Bitte bestätige deine Email-Adresse. Wir melden uns bei dir!"
  },

  package: {
    headline: "Erhalte dein persönliches Ikigai Paket",
    bulletPoints: [
      "Dein persönliches Ikigai als Poster per Email",
      "Persönliches Folienset mit allen Bildern und Texten zu deinem Prozess",
      "Transformations-Skript mit weiteren Informationen zur Ikigai Nutzung"
    ],
    submit: "Jetzt bestellen (15 €)",
    success: "Bitte bestätige deine Email-Adresse. Wir melden uns bei dir!"
  },

  emailSubmit: {
    inProgress: "Wir arbeiten noch am automatischen Bezahlen.",
    prompt: "Gib deine E-Mail-Adresse an. Wir melden uns dann bei dir.",
    placeholder: "mail@example.com",
    disclaimer:
      "**Notiz:** Für den nächsten Schritt müssen wir deine **E-Mail Adresse**, dein **Ikigai** und die Inhalte deiner **Brainstorm Notizen** auf unseren Servern speichern.",
    checkmark: "Ich stimme zu, dass ihr meine Daten speichern dürft.",
    submit: "Jetzt abschicken"
  },

  error: {
    generic: "Das hat nicht geklappt. Versuche es erneut."
  },

  success: {
    generic: "🌱 Das hat geklappt."
  },

  confirm: {
    thanks: "Deine E-Mail Adresse wurde erfolgreich bestätigt!",
    contactYou: "Wir melden uns umgehend bei dir."
  },

  //Buttons und Anweisungen
  ikigai: "IKIGAI",
  purposeTool: " - Werkzeug zur Wegfindung",
  progress: "Fortschritt",
  whoMadeThis: "Wer steckt dahinter?",
  language: "Sprache: ",
  done: "Fertig",
  resetAll: "Nochmal von vorne anfangen",
  areYouSure: "Löscht alle Texte, die du eingegeben hast. Bist du sicher?",
  skip: "Ans Ende springen.",
  print: "Drucken (PDF)",
  back: "Zurück",
  create: "Mein IKIGAI erstellen",
  letsgo: "Los geht's",
  teamCheckin: "Team IKIGAI erstellen",
  howIsIkigai: "Wie funktioniert IKIGAI?",
  whatIsIkigai: "Woher kommt IKIGAI?",
  or: "Oder",
  check: "IKIGAI anschauen",
  postIt: "Aktivität",
  next: "Weiter",
  nextLevel: "Weiter mit Level {{level}}",
  youDidIt: "Wunderbar!",
  circlesFilled: "Kreise gefüllt!",
  fillTheCircles: "Fülle die Kreise",
  reallyStrong: "Fantastisch!",
  formCommonSentence: "Bilde einen essentiellen Satz.",
  combineSentences: "Kombiniere zu einem neuen Satz.",
  overview: "Vollbild",
  sentencesComplete: "Alle Sätze gebildet!",
  finishedWellDone: "Fertig! Gut gemacht.",
  ikigaiComplete: "Dein IKIGAI ist vollständig!",
  sentencePlaceholder: "Ich / Wir ...",

  //Inhalt Prozessbegleitung
  monolog: [
    "Ich sehe in die Welt.",
    "Umweltkatastrophen, politische Spannungen, Ernährungsprobleme. Krisen um Wasser und Wohnraum, Ausbeutung von Mensch und Natur.",
    "Die Welt ist aus der Balance.",
    "Ich verspüre in mir den Drang zu handeln. Ich empfinde Anspannung dabei meine Zeit mit Arbeit zu verbringen, für die ich keinen Sinn finde.",
    "Ich will kreieren, was mir aus der Seele spricht, etwas Wertvolles, das mir Freude bereitet.",
    "Dennoch fühle ich mich kraftlos, manchmal taub.",
    "Ich suche nach der Anbindung an mich selbst, an meine innere Kraft. Ich will mir bewusst werden. 'Erkenne dich selbst', sagten die alten griechischen Philosophen.",
    "Wie kann ich Einfluss auf die Dinge nehmen, die mir am Herzen liegen?",
    "Wie verbinde ich meine Stärken, meine Leidenschaften, meine Berufung, sodass ich in der Welt wirken kann?"
  ],

  result: {
    welcome: "Willkommen auf deinem Fortschritt Screen",
    welcomeSub:
      "Die Kreise füllen sich mit den Aktivitäten und Sätzen, die du auf deiner Reise eingibst.  \nMit jedem Level bewegst du dich eine Ebene tiefer hin zum Kern.  \nBewege dich in deinem Rhythmus und lass dir Zeit.",
    done: "Glückwunsch! Dein Ikigai ist fertig.",
    doneSub: "Wir hoffen, du hast etwas Neues über dich entdeckt.",
    hint:
      "Zum **Editieren** klicke die Überschriften an.  \nAll deine Eingaben werden **in deinem Browser** gespeichert.  \nDu kannst den Tab schließen und jederzeit zurückkommen.",
    hintTiny:
      "Alle deine Eingaben sind **privat** und verlassen deinen Browser nicht."
  },

  brainstormBox: {
    headline: "Brainstorm",
    prompt: "Brainstorm",
    sub:
      "Raum für deine Notizen. Öffne dich für alles, was dir zu den Fragen in den Sinn kommt. Achte darauf, welche Aktivitäten dir einfallen. Fang einfach an und notiere deine Gedanken frei heraus. Sammle Stichworte, die mit dir resonieren. Am Ende wähle drei Aktivitäten, gehe zurück und trage sie in die offenen Felder ein.",
    placeholder: "Aktivität 1, Aktivität 2, ..."
  },

  flower: {
    A: {
      sub: "Fülle deine vier Kreise",
      description:
        "Die vier Kreise in Level 1 bieten dir den Raum für Introspektion. Hier erkundest du deine Leidenschaften und Fähigkeiten, deinen Wunsch nach Verdienst und deine Aufgabe in der Welt. Finde einen ruhigen Ort, gehe in dich und lass dich von den Fragen leiten. Beim Ikigai geht es darum eine passende Handlungsausrichtung zu finden, die deine Lebensfreude ausdrückt. Nutze bei deinen Notizen Verben.",
      prompt:
        "Du wirst Platz zum Brainstormen finden. Dort kannst du frei aufschreiben, was dir in den Sinn kommt. Gib am Ende pro Kreis die drei Aktivitäten ein, die am meisten mit dir resonieren."
    },
    A1: {
      name: "Passion",
      description: "Spüre in deine Leidenschaften.",
      questions: [
        "Welche Aktivitäten bringen dich ganz in deinen Flow?",
        "Was macht dir wahrlich Freude? Was liebst du zu tun?",
        "Was schenkt dir die meiste Energie?"
      ]
    },
    A2: {
      name: "Stärke",
      description: "Erkunde deine Fähigkeiten.",
      questions: [
        "Welche Kompetenzen und Stärken hast du entwickelt?",
        "Worin bist du besonders gut? Was meisterst du?",
        "Worin hast du dich fit gemacht?"
      ]
    },
    A3: {
      name: "Verdienst",
      description: "Entdecke wofür du Wertausgleich möchtest.",
      questions: [
        "Womit verdienst du dein Geld?",
        "Für welches Tun möchtest du Wertschätzung?",
        "Für welche Tätigkeit möchtest du Auslgeich bekommen?"
        // 4. Frage zu viel, und: Evtl. eher A2?
        //"Wobei erfährst du deine meisten Erfolge?"
      ]
    },
    A4: {
      name: "Welt",
      description:
        "Spüre in die Perspektive der Welt. Finde heraus, was dein Teil im großen Puzzle ist.",
      questions: [
        "Was wünscht sich die Welt am dringlichsten von dir?",
        "Womit kannst du zu einer florierenden Welt beitragen?",
        "Wie kannst du der Welt dienen?"
      ]
    },
    B: {
      sub: "Forme stimmige Sätze",
      description:
        "In Level 2 rücken deine vier Kreise näher und überschneiden sich. Es entstehen vier neue Schnittmengen. Erkunde die neu entstandenen Schnittmengen auf sich ähnelnde Verben. Finde einen Satz, der die Essenz aus je zwei Kreisen enthält.",
      prompt:
        "Schreibe jeweils einen Satz in der Form 'Ich *VERB* ...'. Beispiele: 'Ich gestalte... für...', 'Ich entwickle... mit...', 'Ich bewege... hin zu...', 'Ich mache...'."
    },
    B1: {
      name: "Entfaltung"
    },
    B2: {
      name: "Profession"
    },
    B3: {
      name: "Verantwortung"
    },
    B4: {
      name: "Berufung"
    },
    C: {
      sub: "Kondensiere in neue Sätze",
      description:
        "Mit Level 3 bewegst du dich tiefer in den Prozess hinein. Je zwei Sätze aus Level 2 überschneiden sich nun. Erkunde die Essenz aus den zwei Sätzen, die dir angezeigt werden. Kondendensiere sie zu einem stimmigen neuen Satz. Achte auf deine Intuition. Folge deinen Impulsen. Du kannst das!",
      prompt:
        "Atme tief durch. Finde Ruhe. Schreibe einen neuen Satz der Form 'Ich *VERB* ...'"
    },
    C1: {
      name: "Mission"
    },
    C2: {
      name: "Verwirklichung"
    },
    C3: {
      name: "Dienst"
    },
    C4: {
      name: "Einfluss"
    },
    D: {
      name: "Sinn des Lebens",
      sub: "Dein Purpose Satz",
      description:
        "Du hast den inneren Kern erreicht. Koche deine vier letzten Sätze zu einer gemeinsamen Essenz ein. Entdecke die Natur deines Ikigai.",
      prompt: "Verbinde dich mit deinem inneren Herzraum, indem du tief in deine Brust und in den Bauch einatmest. Spreche deine letzten vier Sätze laut aus. Du kannst das mehrmals wiederholen. Öffne dich für Impulse und Inspiration. Bilde einen letzten, für dich stimmigen Satz."
    }
  }
});

export default i18n;
