import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import bg from "../img/bg4-low.jpg";
import logo from "../img/redlogo.png";

import Button from "../common/Button";
import FaIcon from "../common/FaIcon";
import Markdown from "../common/Markdown";

import { MediaMinDesktop } from "../common/MediaQuery";

import { MOBILE_MAX, TABLET_MAX } from "../settings";

const All = styled("div")``;
const Section = styled("div")`
  padding: 100px;
  @media (max-width: ${TABLET_MAX}) {
    padding: 60px 40px;
  }
  @media (max-width: ${MOBILE_MAX}) {
    padding: 60px 20px;
  }
`;

const FullScreen = styled("div")`
  height: 100vh;
  width: 100%;
  position: relative;
`;

const Bg = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  filter: brightness(80%);
  background: url(${bg});
  background-position: center;
  background-size: cover;
  z-index: -1;
`;

const Content = styled("div")`
  display: grid;
  grid-template: "head head" "left right" "discleft discright" / 1fr 1fr;
  grid-gap: 30px;
  padding: 0 100px;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: ${TABLET_MAX}) {
    grid-gap: 10px;
    padding: 20px 40px;
    grid-template: "head" "left" "right" "discleft" "discright" / 1fr;
  }

  @media (max-width: ${MOBILE_MAX}) {
    padding: 20px;
  }
`;

const Head = styled("div")`
  grid-area: head;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

const Left = styled("div")`
  grid-area: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Right = styled("div")`
  grid-area: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Logo = styled("img")`
  height: 30px;
  width: 30px;
  margin-right: 15px;
  flex-shrink: 0;
`;

const SxButton = styled(Button)`
  font-size: 20px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  color: #666;
  margin-top: 20px;

  @media (max-width: ${MOBILE_MAX}) {
    margin-top: 0px;
    font-size: 18px;
    padding: 8px 15px;
  }
`;

const Headline = styled("h1")`
  font-size: 100px;
  margin: 0;
  color: white;
  text-shadow: 0 0 1px white;
  line-height: 1;

  @media (max-width: ${MOBILE_MAX}) {
    font-size: 40px;
  }
`;

const Tagline = styled("h3")`
  font-size: 40px;
  margin: 0;
  color: white;
  text-shadow: 0 0 1px white;
  margin-bottom: 20px;
  line-height: 1.1;

  @media (max-width: ${MOBILE_MAX}) {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const BtnText = styled("span")`
  padding: 0 20px 0 0;
  line-height: 1;
`;

const SxIcon = styled(FaIcon)`
  color: #666;
`;

const Description = styled("p")`
  font-size: 18px;
  max-width: 600px;
  color: white;
  text-shadow: 0 0 1px white;
  margin: 5px 0;
  font-weight: 400;
  margin-bottom: 10px;

  @media (max-width: ${MOBILE_MAX}) {
    font-size: 14px;
  }
`;

const Subline = styled("h2")`
  margin: 0 0 10px;
  font-size: 24px;
  color: white;
  text-shadow: 0 0 1px white;

  @media (max-width: ${MOBILE_MAX}) {
    font-size: 16px;
  }
`;

const DisclaimerLeft = styled("div")`
  grid-area: discleft;
`;
const DisclaimerRight = styled("div")`
  grid-area: discright;
`;

const Disclaimer = styled("p")`
  margin: 20px 0 0;
  font-size: 14px;
  text-shadow: 0 0 1px white;
  color: white;
  a {
    color: white;
  }
`;

const PoemWrap = styled("div")`
  text-align: center;
`;
const Poem = styled("div")`
  display: inline-block;
  text-align: left;
`;
const PoemLine = styled("p")`
  font-size: 18px;
  font-style: italic;
  margin: 0 0 8px;

  &:first-of-type {
    font-weight: 700;
    margin: 0 0 10px;
  }

  @media (max-width: ${MOBILE_MAX}) {
    font-size: 14px;
  }
`;

export default withTranslation()(({ t, location, history }) => {
  const descriptionsLeft = t("landing.descriptionLeft", {
    returnObjects: true
  });
  const descriptionsRight = t("landing.descriptionRight", {
    returnObjects: true
  });
  const descriptionsRightTiny = t("landing.descriptionRightTiny", {
    returnObjects: true
  });
  const poem = t("landing.poem", {
    returnObjects: true
  });

  return (
    <All>
      <FullScreen>
        <Bg />
        <Content>
          <Head>
            <Headline>{t("landing.headline")}</Headline>
            <Tagline>{t("landing.tagline")}</Tagline>
          </Head>
          <Left>
            <Subline>{t("landing.headLeft")}</Subline>
            <Description>
              <Markdown text={descriptionsLeft[0]} />
            </Description>
            <Description>
              <Markdown text={descriptionsLeft[1]} />
            </Description>
            <Description>
              <Markdown text={descriptionsLeft[2]} />
            </Description>
            <Description>
              <Markdown text={descriptionsLeft[3]} />
            </Description>
          </Left>
          <MediaMinDesktop></MediaMinDesktop>
          <Right>
            <MediaMinDesktop>
              <Subline>{t("landing.headRight")}</Subline>
              {descriptionsRight.map(desc => (
                <Description>
                  <Markdown text={desc} />
                </Description>
              ))}
            </MediaMinDesktop>
            <SxButton onClick={() => history.push("/intro")}>
              <Logo src={logo} />
              <BtnText>{t("landing.start")}</BtnText>
              <SxIcon icon="arrow-right" />
            </SxButton>
          </Right>
          <MediaMinDesktop>
            <DisclaimerLeft>
              {descriptionsRightTiny.map(desc => (
                <Disclaimer>
                  <Markdown text={desc} />
                </Disclaimer>
              ))}
            </DisclaimerLeft>
          </MediaMinDesktop>
        </Content>
      </FullScreen>
      <Section>
        <PoemWrap>
          <Poem>
            {poem.map(line => (
              <PoemLine>{line}</PoemLine>
            ))}
          </Poem>
        </PoemWrap>
      </Section>
    </All>
  );
});
