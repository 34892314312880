import * as React from "react";
import { withTranslation } from "react-i18next";

import FaIcon from "./FaIcon";

import { LANGUAGES } from "../config";

function nextLang(i18n) {
  const idx = LANGUAGES.indexOf(i18n.language.slice(0, 2));
  const lang = LANGUAGES[(idx + 1) % LANGUAGES.length];

  i18n.changeLanguage(lang);
}

export default withTranslation()(({ t, i18n }) => {
  return (
    <div onClick={() => nextLang(i18n)}>
      <FaIcon icon="globe-europe" /> {t("language")} {i18n.language}
    </div>
  );
});
