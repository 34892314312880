import * as React from "react";
import styled from "@emotion/styled";
import Hotkeys from "react-hot-keys";

import { withTranslation } from "react-i18next";

import { MOBILE_MAX, TABLET_MAX } from "../settings";

import { MediaMaxTablet, MediaMinDesktop } from "../common/MediaQuery";
import appear from "../animation/appear";

import FaIcon from "../common/FaIcon";
import Button from "../common/Button";
import Markdown from "../common/Markdown";

import Flower from "../editing/Flower";

const Root = styled("div")`
  text-align: left;
  display: grid;
  max-width: 1400px;
  margin: 0 auto;
  grid-template: "flower text" / 50% 50%;

  @media (max-width: ${TABLET_MAX}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled("p")`
  margin: 10px 0;
  line-height: 1.3;
  font-size: 18px;
  color: #333;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TinyText = styled("p")`
  line-height: 1.3;
  font-size: 14px;
  color: #666;
  margin: 5px 0;
`;

const SxButton = styled(Button)`
  margin-bottom: 20px;
`;

const TextContainer = styled("div")`
  grid-area: text;
  max-width: 600px;
  margin-bottom: 10px;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  z-index: 1;

  @media (max-width: ${MOBILE_MAX}) {
    margin: 0 auto;
  }
`;

const SxFlower = styled(Flower)`
  grid-area: flower;

  @media (max-width: ${TABLET_MAX}) {
    height: 300px;
  }
`;

const Row = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const Sentences = styled("div")`
  animation: ${appear} 0.2s ease forwards;
`;

export default withTranslation()(({ t, history }) => {
  const sentencesWhat = t("intro.descriptionWhat", { returnObjects: true });
  const sentencesHow = t("intro.descriptionHow", { returnObjects: true });
  const sentencesTiny = t("intro.descriptionTiny", { returnObjects: true });

  const [step, setStep] = React.useState(0);

  const onClose = () => {
    history.goBack();
  };

  return (
    <Root>
      <Hotkeys keyName="escape" onKeyDown={onClose} />
      <MediaMaxTablet>
        <SxFlower
          mCanvas={{ w: 300, h: 300 }}
          mRad={170}
          mSpace={65}
          mBorder={5}
          mPad={20}
          labelFS={10}
          dText="IKIGAI"
          noDLabel
        />
      </MediaMaxTablet>
      <MediaMinDesktop>
        <SxFlower
          noDLabel
          mCanvas={{ w: 650, h: 700 }}
          mSpace={120}
          mRad={340}
          dText="IKIGAI"
        />
      </MediaMinDesktop>
      <TextContainer>
        <Row>
          <MediaMinDesktop>
            <SxButton onClick={step === 0 ? onClose : () => setStep(0)}>
              <FaIcon icon="arrow-left" /> {t("back")}
            </SxButton>
          </MediaMinDesktop>
          {step === 0 && (
            <MediaMinDesktop>
              <SxButton onClick={() => setStep(1)}>
                <FaIcon icon="question-circle" /> {t("whatIsIkigai")}
              </SxButton>
            </MediaMinDesktop>
          )}
        </Row>
        <Sentences>
          {step === 0 &&
            sentencesHow.map((sentence, i) => (
              <Text key={i}>
                <Markdown text={sentence} />
              </Text>
            ))}
        </Sentences>
        <Sentences>
          {step === 1 &&
            sentencesWhat.map((sentence, i) => (
              <Text key={i}>
                <Markdown text={sentence} />
              </Text>
            ))}
        </Sentences>
        {sentencesTiny.map((sentence, i) => (
          <TinyText key={i}>
            <Markdown text={sentence} />
          </TinyText>
        ))}
        <Row>
          <MediaMaxTablet>
            <SxButton onClick={onClose}>
              <FaIcon icon="arrow-left" /> {t("back")}
            </SxButton>
          </MediaMaxTablet>
          {step === 0 && (
            <MediaMaxTablet>
              <SxButton onClick={() => setStep(1)}>
                <FaIcon icon="question-circle" /> {t("whatIsIkigai")}
              </SxButton>
            </MediaMaxTablet>
          )}
        </Row>
      </TextContainer>
    </Root>
  );
});
