import env from "./env";

export const ENV = env;

// Level 1 = POST_ITS * CIRCLES values
export const POST_ITS_COUNT = 3;
export const CIRCLES_COUNT = 4;

// Level 2 = 4 values
// Level 3 = 4 values
// Level 4 = 1 value
export const STEPS_TO_LVL_2 = CIRCLES_COUNT * POST_ITS_COUNT;
export const STEPS_TO_LVL_3 = STEPS_TO_LVL_2 + CIRCLES_COUNT;
export const STEPS_TO_LVL_4 = STEPS_TO_LVL_3 + CIRCLES_COUNT;

export const TOTAL_COUNT = STEPS_TO_LVL_4 + 1;

export const LANGUAGES = ["de", "en"];
