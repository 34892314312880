import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import { keyframes } from "@emotion/core";
import { Switch, Route } from "react-router-dom";

import Headline from "../common/Headline";
import Input from "../common/Input";
import Frame from "../common/Frame";
import FaIcon from "../common/FaIcon";
import Button from "../common/Button";

import Flower from "./Flower";
import LevelConnect from "./LevelConnect";

import { postIkigai } from "../api/requests";
import { STEPS_TO_LVL_3, STEPS_TO_LVL_4 } from "../config";

const SxFrame = styled(Frame)`
  max-width: 400px;
  margin: 0 auto;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
`;

const SxHeadline = styled(Headline)`
  margin: 0;
  text-align: left;
`;
const Left = styled("div")``;

const Sub = styled("h2")`
  margin: 5px 0 0;
  color: #888;
  font-size: 20px;
  line-height: 1.1;
`;

const Group = styled("div")`
  width: 100%;
`;

const SxButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const BunchHead = styled("h3")`
  color: #666;
  margin: 0;
`;

const Bunch = styled("div")`
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
`;

const Q = styled("p")`
  margin: 15px 0;
  color: #666;
  line-height: 1.3;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SxFlower = styled(Flower)`
  position: absolute;
  z-index: -1;
  top: 0;
`;

const appear = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Dialog = styled("div")`
  width: 100%;
  animation: ${appear} 0.2s ease forwards;
`;

const Intro = styled(BunchHead)`
  font-weight: 400;
  margin-bottom: 20px;
`;

const getConfig = () => {
  return {
    mx: 0,
    my: 0,
    mSpace: 160,
    mRad: 600,
    mBorder: 7,
    S: 12
  };
};

export default LevelConnect(
  withTranslation()(({ t, history, location, vals, setVals }) => {
    const params = new URLSearchParams(location.search);

    const [val, setVal] = React.useState(vals[STEPS_TO_LVL_4] || "");

    const flowerConf = getConfig();
    const text = t("flower.D.name");

    return (
      <SxFrame>
        <Row>
          <Left>
            <SxHeadline>{text}</SxHeadline>
            <Sub>{t("combineSentences")}</Sub>
          </Left>
        </Row>
        <Switch>
          <Route path="/level4/intro">
            <Bunch>
              <Intro>{t("flower.D.description")}</Intro>
              <BunchHead>{t("flower.D.prompt")}</BunchHead>
            </Bunch>
            <SxButton
              onClick={() => {
                const nextParams = new URLSearchParams(location.search);

                history.push(`/level4?${nextParams}`);
              }}
            >
              {t("letsgo")}
            </SxButton>
          </Route>
          <Route>
            <Dialog>
              {[0, 1, 2, 3].map(i => (
                <Bunch key={i}>
                  <BunchHead>{t(`flower.C${i + 1}.name`)}</BunchHead>
                  <Q>{vals[STEPS_TO_LVL_3 + i]}</Q>
                </Bunch>
              ))}
              <Group>
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    setVals(val, STEPS_TO_LVL_4);
                    setVal("");

                    postIkigai(params.get("n"), vals);

                    const nextParams = new URLSearchParams(location.search);

                    nextParams.set("l", 4);

                    history.push(`/progress?${nextParams.toString()}`);
                  }}
                >
                  <Input
                    placeholder={t("sentencePlaceholder")}
                    value={val}
                    onChange={e => setVal(e.target.value)}
                  />
                  <SxButton primary={true} type="submit" disabled={!val}>
                    Weiter <FaIcon primary icon="arrow-right" />
                  </SxButton>
                </form>
              </Group>
            </Dialog>
          </Route>
        </Switch>
        <SxFlower {...flowerConf} noLabels />
      </SxFrame>
    );
  })
);
