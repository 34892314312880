import { STEPS_TO_LVL_2, STEPS_TO_LVL_3, STEPS_TO_LVL_4 } from "./config";

export function sanitize(s) {
  if (!s) return "";

  return s.replace(/[;<>]*/g, "");
}

export function isLevelDisabled(lev, vals) {
  const valsLen = vals.filter(v => !!v).length;

  switch (lev) {
    case 1:
      return false;
    case 2:
      return valsLen < 16;
    case 3:
      return valsLen < 20;
    case 4:
      return valsLen < 21;
    default:
      return true;
  }
}

export function getNext(vals) {
  const firstNullIdx = vals.indexOf(null);

  if (firstNullIdx === -1) {
    return {};
  }

  let nextLevel = 0;
  let nextStep = 0;

  if (firstNullIdx < STEPS_TO_LVL_2 - 1) {
    nextLevel = 1;
    nextStep = firstNullIdx;
  } else if (firstNullIdx < STEPS_TO_LVL_3 - 1) {
    nextLevel = 2;
    nextStep = firstNullIdx - STEPS_TO_LVL_2;
  } else if (firstNullIdx < STEPS_TO_LVL_4 - 1) {
    nextLevel = 3;
    nextStep = firstNullIdx - STEPS_TO_LVL_3;
  } else {
    nextLevel = 4;
    nextStep = firstNullIdx - STEPS_TO_LVL_3;
  }

  return {
    nextLevel,
    nextStep
  };
}
