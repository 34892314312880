import * as React from "react";
import styled from "@emotion/styled";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loading = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Text = styled("span")`
  margin-left: 10px;
`;

const Item = styled("h2")`
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export default () => (
  <Loading>
    <Item>
      <ScaleLoader height={15} width={2} radius={2} />
      <Text>Loading</Text>
    </Item>
  </Loading>
);
