import * as React from "react";

import Frame from "../common/Frame";
import Headline from "../common/Headline";

import Flower from "./Flower";

export default ({ location }) => {
  return (
    <Frame>
      <Headline>Playground</Headline>
      <Flower
        controls
        dText="IKIGAI"
        noDLabel
        b1Text=" "
        b2Text=" "
        b3Text=" "
        b4Text=" "
      />
    </Frame>
  );
};
