import * as React from "react";
import Frame from "./Frame";

const buildDate = process.env.REACT_APP_BUILD_DATE;
const buildTime = process.env.REACT_APP_BUILD_TIME;

export default () => (
  <Frame>
    <p>{buildTime}</p>
    <p>{buildDate}</p>
  </Frame>
);
