import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Hotkeys from "react-hot-keys";

import logo from "../img/redlogo.png";
import { MOBILE_MAX } from "../settings";

import FaIcon from "./FaIcon";
import Button from "./Button";
import TransparentButton from "./TransparentButton";
import LanguagePicker from "./LanguagePicker";
import OutsideClickContainer from "./OutsideClickContainer";
import { MediaMaxMobile } from "./MediaQuery";

const Menu = styled("div")`
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 40px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 10px 20px;

  @media (max-width: ${MOBILE_MAX}) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    padding: 0;
  }
`;

const Logo = styled("img")`
  width: 30px;
  height: 30px;
  cursor: pointer;

  hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const MenuItem = styled(Button)`
  padding: 15px 25px;
  font-size: 24px;
  text-align: left;
  text-decoration: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  transition: background-color 0.1s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const CloseBtn = styled(TransparentButton)`
  font-size: 24px;
`;

const link = css`
  text-decoration: none;
  color: #333;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
  display: block;
`;

const SxLink = styled(Link)`
  ${link};
`;

const SxA = styled("a")`
  ${link};
`;

const Headline = styled("div")`
  padding: 6px 20px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled("div")`
  @media (max-width: ${MOBILE_MAX}) {
    padding: 10px 20px;
  }
`;

export default withTranslation()(({ t, onClose }) => {
  return (
    <OutsideClickContainer onClickOutside={onClose}>
      <Menu>
        <MediaMaxMobile>
          <Headline>
            <Logo src={logo} onClick={onClose} />

            <CloseBtn onClick={onClose}>
              <FaIcon icon="times" />
            </CloseBtn>
          </Headline>
        </MediaMaxMobile>
        <Content>
          <Hotkeys keyName="escape" onKeyDown={onClose} />
          <SxLink onClick={onClose} to="/">
            <MenuItem>
              <FaIcon icon="arrow-left" /> {t("intro.name")}
            </MenuItem>
          </SxLink>
          <SxLink onClick={onClose} to="/about-ikigai">
            <MenuItem>
              <FaIcon icon="question-circle" /> {t("howIsIkigai")}
            </MenuItem>
          </SxLink>
          <SxLink onClick={onClose} to="/done">
            <MenuItem>
              <FaIcon icon="hippo" /> {t("whoMadeThis")}
            </MenuItem>
          </SxLink>
          <MenuItem>
            <LanguagePicker />
          </MenuItem>
          <SxA
            onClick={onClose}
            rel="noopener noreferrer"
            target="_blank"
            href="https://tram266255.typeform.com/to/cQhhXN"
          >
            <MenuItem>
              <FaIcon icon="external-link-alt" /> {t("Feedback")}
            </MenuItem>
          </SxA>
        </Content>
      </Menu>
    </OutsideClickContainer>
  );
});
