import * as React from "react";
import { withRouter } from "react-router";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import logo from "../img/redlogo.png";
import FaIcon from "./FaIcon";
import TransparentButton from "./TransparentButton";
import Menu from "./Menu";
import { MediaMinTablet } from "./MediaQuery";

const Root = styled("div")`
  padding: 8px 20px;
  width: 100%;
  margin-bottom: 10px;
  background: linear-gradient(to bottom, #f5f5f5, #f8f8f8);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);

  @media print {
    display: none;
  }
`;

const Logo = styled("img")`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: opacity 0.1s;

  &:hover {
    opacity: 0.7;
  }
`;

const Content = styled("div")`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BurgerMenuBtn = styled(TransparentButton)`
  font-size: 20px;
`;

const Headline = styled("p")`
  margin: 0;
  font-size: 20px;
  margin-left: 20px;
  color: #666;
  font-weight: 700;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
`;

export default withTranslation()(
  withRouter(({ t, history, location }) => {
    const [showMenu, setShowMenu] = React.useState(false);

    const getHeadline = (t, pathname) => {
      if (
        pathname === "/" ||
        pathname === "/main" ||
        pathname === "/intro" ||
        pathname.indexOf("/done") !== -1 ||
        pathname.indexOf("/about-ikigai") !== -1
      )
        return (
          <>
            {t("IKIGAI")}
            <MediaMinTablet>{t("purposeTool")}</MediaMinTablet>
          </>
        );
      else if (pathname.indexOf("level1") !== -1) return t("Level 1");
      else if (pathname.indexOf("level2") !== -1) return t("Level 2");
      else if (pathname.indexOf("level3") !== -1) return t("Level 3");
      else if (pathname.indexOf("level4") !== -1) return t("Level 4");
      else if (pathname.indexOf("/progress") !== -1) return t("progress");
      else if (pathname.indexOf("/download") !== -1) return t("overview");
      else return "";
    };

    const headline = getHeadline(t, location.pathname);

    return (
      <Root>
        <Content>
          <Row>
            <Logo src={logo} onClick={() => history.push(`/progress`)} />
            <Headline>{headline}</Headline>
          </Row>
          <BurgerMenuBtn onClick={() => setShowMenu(!showMenu)}>
            <FaIcon icon="bars" />
          </BurgerMenuBtn>
          {showMenu && <Menu onClose={() => setShowMenu(false)} />}
        </Content>
      </Root>
    );
  })
);
