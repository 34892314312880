import * as React from "react";
import styled from "@emotion/styled";
import Hotkeys from "react-hot-keys";

import { withTranslation } from "react-i18next";

import FaIcon from "../common/FaIcon";
import Button from "../common/Button";

const Root = styled("div")`
  max-width: 400px;
  margin: 20px auto;
`;

const Padded = styled("div")`
  padding: 0 20px;
`;

const Content = styled("div")`
  text-align: left;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  margin: 0 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Text = styled("p")`
  margin: 10px 0;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 400;
  color: #333;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SxButton = styled(Button)`
  margin-bottom: 20px;
`;

export default withTranslation()(({ t, history }) => {
  const onClose = () => {
    history.goBack();
  };

  return (
    <Root>
      <Padded>
        <SxButton onClick={onClose}>
          <FaIcon icon="arrow-left" /> {t("back")}
        </SxButton>
      </Padded>
      <Content>
        <Text>
          {t("finished.cooperation")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://de.linkedin.com/in/ngantramhodac"
          >
            Tram
          </a>{" "}
          &{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/kai-rollmann-a2811577/"
          >
            Kai
          </a>
          .
        </Text>
        <Text>{t("finished.thanks")}</Text>
      </Content>
      <Hotkeys keyName="escape" onKeyDown={onClose} />
    </Root>
  );
});
