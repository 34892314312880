import * as React from "react";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

// Since Font Awesome 5, there's a dedicated react component
//
// To avoid importing icons individually in every file
// we're buildling our own library, see:
// https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
library.add(fas, far);

const shouldForwardProp = (prop: any) =>
  isPropValid(prop) || prop === "icon" || prop === "className";

export const Icon = styled(FontAwesomeIcon, { shouldForwardProp })`
  font-size: ${({ large }) => (large ? "16px" : "inherit")};
  color: ${({ primary }) => (primary ? "white" : "#333")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "inherit")};

  ${({ icon }) =>
    icon === "spinner" &&
    css`
      animation: ${spinAnimation} 0.5s linear infinite;
    `};
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const FaIcon = ({ icon, regular, className, ...restProps }) => {
  return (
    <Icon
      className={`fa ${className}`}
      icon={regular ? ["far", icon] : icon}
      {...restProps}
    />
  );
};

export default FaIcon;
