import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import Frame from "../common/Frame";
import Button from "../common/Button";
import FaIcon from "../common/FaIcon";
import Headline from "../common/Headline";

import Flower from "../editing/Flower";

import Form from "./Form";

const SxFrame = styled(Frame)`
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Cols = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  width: 100%;
`;

const Bg = styled("div")`
  position: absolute;
  z-index: -1;
  top: 0;
  left: -120px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AboutBtn = styled(Button)`
  margin-bottom: 30px;
  width: 100%;
`;
const SxHeadline = styled(Headline)`
  border-bottom: none;
`;

export default withTranslation()(({ t, location, history }) => {
  const params = new URLSearchParams(location.search);

  return (
    <SxFrame>
      <Cols>
        <SxHeadline>
          {t("intro.title")}
          <br />
          {t("intro.subtitle")}
        </SxHeadline>
        <AboutBtn onClick={() => history.push("/about-ikigai")}>
          <FaIcon icon="question-circle" /> {t("howIsIkigai")}
        </AboutBtn>
        <Form
          onStart={() => {
            params.set("s", 0);

            history.push(`/level1/intro?${params}`);
          }}
        />
        <Bg>
          <Flower noLabels mCanvas={{ w: 600, h: 520 }} />
        </Bg>
      </Cols>
    </SxFrame>
  );
});
