import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import { keyframes } from "@emotion/core";
import { Switch, Route } from "react-router-dom";

import Headline from "../common/Headline";
import Input from "../common/Input";
import Frame from "../common/Frame";
import FaIcon from "../common/FaIcon";
import Button from "../common/Button";

import Flower from "./Flower";
import ProgressBar from "./ProgressBar";
import LevelConnect from "./LevelConnect";

import { STEPS_TO_LVL_2, STEPS_TO_LVL_3, CIRCLES_COUNT } from "../config";

import { postIkigai } from "../api/requests";

const SxFrame = styled(Frame)`
  max-width: 400px;
  margin: 0 auto;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
`;

const SxHeadline = styled(Headline)`
  margin: 0;
  text-align: left;
`;
const Left = styled("div")``;

const Sub = styled("h2")`
  margin: 5px 0 0;
  color: #888;
  font-size: 20px;
  line-height: 1.1;
`;

const Group = styled("div")`
  width: 100%;
`;

const SxButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const BunchHead = styled("h3")`
  color: #666;
  margin: 0;
`;

const Bunch = styled("div")`
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
`;

const Q = styled("p")`
  margin: 15px 0;
  color: #666;
  line-height: 1.3;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SxFlower = styled(Flower)`
  position: absolute;
  z-index: -1;
  top: 0;
`;

const appear = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Dialog = styled("div")`
  width: 100%;
  animation: ${appear} 0.2s ease forwards;
`;

const IntroContainer = styled("div")`
  width: 100%;
  padding: 20px 0;
`;

const Intro = styled(BunchHead)`
  font-weight: 400;
`;

const Prompt = styled(BunchHead)`
  margin-top: 20px;
`;

const getConfig = (intersection, isIntro) => {
  if (isIntro) {
    return {
      mx: 0,
      my: 20,
      mSpace: 200,
      mRad: 650,
      mBorder: 7,
      S: 12
    };
  }

  switch (intersection) {
    case 0:
      return {
        mx: 0,
        my: 200
      };
    case 1:
      return {
        mx: 200,
        my: 0
      };
    case 2:
      return {
        mx: 0,
        my: -200
      };
    case 3:
      return {
        mx: -200,
        my: 0
      };
    default:
      return {};
  }
};

const MAX_STEPS = CIRCLES_COUNT; // Because this level combines two of each circles again

export default LevelConnect(
  withTranslation()(({ t, history, location, vals, setVals }) => {
    const params = new URLSearchParams(location.search);
    const step = parseInt(params.get("s"));
    const idx = STEPS_TO_LVL_3 + step;

    const [val, setVal] = React.useState(vals[idx] || "");

    const isIntro = location.pathname.includes("intro");
    const flowerConf = getConfig(step, isIntro);
    const text =
      step < MAX_STEPS ? t(`flower.C${step + 1}.name`) : t("sentencesComplete");

    React.useEffect(() => {
      setVal(vals[idx] || "");
    }, [vals, idx]);

    return (
      <SxFrame>
        <Switch>
          <Route path="/level3/intro">
            <IntroContainer>
              <SxHeadline>Level 3</SxHeadline>
              <Sub>{t(`flower.C.sub`)}</Sub>
            </IntroContainer>
            <Bunch>
              <Intro>{t("flower.C.description")}</Intro>
              <Prompt>{t("flower.C.prompt")}</Prompt>
            </Bunch>
            <SxButton
              onClick={() => {
                const nextParams = new URLSearchParams(location.search);

                history.push(`/level3?${nextParams}`);
              }}
            >
              {t("letsgo")}
            </SxButton>
          </Route>
          <Route>
            <Row>
              <Left>
                <SxHeadline>{text}</SxHeadline>
                <Sub>{t("combineSentences")}</Sub>
              </Left>
            </Row>
            <Dialog>
              <ProgressBar value={step} max={MAX_STEPS} />
              <Bunch>
                <BunchHead>
                  {t(`flower.B${step === 0 ? CIRCLES_COUNT : step}.name`)}
                </BunchHead>
                <Q>{vals[STEPS_TO_LVL_2 + step]}</Q>
              </Bunch>
              <Bunch>
                <BunchHead>{t(`flower.B${step + 1}.name`)}</BunchHead>
                <Q>{vals[STEPS_TO_LVL_2 + ((step + 1) % MAX_STEPS)]}</Q>
              </Bunch>
              <Group>
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    setVals(val, STEPS_TO_LVL_3 + step);
                    setVal("");

                    const nextStep = step + 1;
                    const nextParams = new URLSearchParams(location.search);

                    if (nextStep >= CIRCLES_COUNT) {
                      postIkigai(params.get("n"), vals);

                      nextParams.set("l", 3);

                      history.push(`/progress?${nextParams.toString()}`);
                    } else {
                      nextParams.set("s", nextStep);

                      history.push(`/level3?${nextParams}`);
                    }
                  }}
                >
                  <Input
                    placeholder={t("sentencePlaceholder")}
                    value={val}
                    onChange={e => setVal(e.target.value)}
                  />
                  <SxButton primary={true} type="submit" disabled={!val}>
                    Weiter <FaIcon primary icon="arrow-right" />
                  </SxButton>
                </form>
              </Group>
            </Dialog>
          </Route>
        </Switch>
        <SxFlower
          mSpace={400}
          mRad={1200}
          mBorder={20}
          {...flowerConf}
          noLabels
        />
      </SxFrame>
    );
  })
);
