import * as React from "react";
import Typist from "react-typist";
import { withTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import Hotkeys from "react-hot-keys";

import { MOBILE_SMALL_MAX } from "../settings";

import Button from "../common/Button";

const Root = styled("div")`
  max-width: 500px;
  margin: 0 auto;
  padding: 10px 20px;
`;

const appear = keyframes`
  to {
    opacity: 1;
  }
`;

const SkipBtn = styled("p")`
  color: #666;
  font-size: 12px;
  text-decoration: none;
  text-align: right;
  display: block;
  opacity: 0;
  margin: 0;
  cursor: pointer;

  animation: ${appear} 8s ease 8s forwards;
`;

const SxButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

const Monolog = styled("div")`
  margin-top: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Headline = styled("h3")`
  font-size: 18px;
  margin: 0;
  color: #666;
`;

const SxTypist = styled(Typist)`
  text-align: left;
`;
const Text = styled("div")`
  margin: 5px 0;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 400;
  color: #333;

  @media (max-width: ${MOBILE_SMALL_MAX}) {
    font-size: 16px;
  }

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default withTranslation()(({ t, history }) => {
  const [step, setStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(false);

  const onDone = () => {
    history.push("/main");
    window.scrollTo(0, 0);
  };

  const onDoneDelayed = () => setTimeout(onDone, 3000);

  const sentences = t("monolog", { returnObjects: true });

  return (
    <Root>
      <Headline>{t("intro.headline")}</Headline>
      {!skipped && (
        <SkipBtn onClick={() => setSkipped(true)}>{t("skip")}</SkipBtn>
      )}
      <Monolog>
        <Hotkeys keyName="enter" onKeyDown={onDone} />
        {skipped ? (
          <>
            {sentences.map(sentence => (
              <Text key={sentence}>{sentence}</Text>
            ))}
            <SxButton onClick={() => history.push("/main")}>
              {t("letsgo")}
            </SxButton>
          </>
        ) : (
          <>
            {sentences.slice(0, step).map(sentence => (
              <Text key={sentence}>
                <SxTypist
                  avgTypingDelay={58}
                  stdTypingDelay={20}
                  cursor={{ show: false }}
                  onTypingDone={() =>
                    step === sentences.length
                      ? onDoneDelayed()
                      : setStep(step + 1)
                  }
                >
                  <Typist.Delay ms={400} />
                  {sentence}
                  <Typist.Delay ms={700} />
                </SxTypist>
              </Text>
            ))}
          </>
        )}
      </Monolog>
    </Root>
  );
});
