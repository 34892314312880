// @flow

import * as React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "./localization/i18n";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import Loading from "./common/Loading";

ReactDOM.render(
  <React.Suspense fallback={<Loading />}>
    <App />
  </React.Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
