import React from "react";
import onClickOutside from "react-onclickoutside";

class ContentComponent extends React.Component {
  handleClickOutside = e => {
    this.props.onClickOutside();
  };

  render() {
    return <>{this.props.children}</>;
  }
}

export default onClickOutside(ContentComponent);
