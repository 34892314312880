import * as React from "react";
import ReactMarkdown from "react-markdown";

const allowedMarkdownTypes = [
  "text",
  "break",
  "emphasis",
  "strong",
  "inlineCode",
  "link",
  "delete"
];

export default ({ text }) => (
  <ReactMarkdown
    source={text}
    allowedTypes={allowedMarkdownTypes}
    unwrapDisallowed={true}
    renderers={{ root: "span" }}
  />
);
