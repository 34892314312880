import * as React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

// import Input from "../common/Input";
import Button from "../common/Button";
import FaIcon from "../common/FaIcon";

const enter = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

const Form = styled("div")`
  animation: ${enter} 0.2s ease-in forwards;
  margin: 0;
  width: 100%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Or = styled("p")`
  margin: 10px 0;
  text-transform: uppercase;
`;

const SxButton = styled(Button)`
  width: 100%;
`;

const SxForm = styled("form")`
  width: 100%;
`;

export default withTranslation()(
  withRouter(({ t, history, location, className, onStart }) => {
    const [teamname, setTeamname] = React.useState("");

    const params = new URLSearchParams(location.search);

    // n = Team name
    const n = params.get("n");

    return (
      <Form>
        <SxForm
          onSubmit={e => {
            e.preventDefault();

            onStart();
          }}
        >
          <SxButton primary type="submit">
            {t("create")} <FaIcon primary icon="arrow-right" />
          </SxButton>
        </SxForm>
        {!n && (
          <>
            <Or>{t("or")}</Or>
            <SxForm
              className={className}
              onSubmit={e => {
                e.preventDefault();

                history.push(`/?n=${teamname}`);
              }}
            >
              <SxButton primary type="submit" disabled={!teamname}>
                {t("teamCheckin")} <FaIcon primary icon="arrow-right" />
              </SxButton>
            </SxForm>
          </>
        )}
      </Form>
    );
  })
);
// <Input
//   value={teamname}
//   placeholder="Team Name"
//   onChange={e => setTeamname(e.target.value)}
// />
