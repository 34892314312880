import styled from "@emotion/styled";

import { MOBILE_SMALL_MAX } from "../settings";

const Button = styled("button")`
  border: 0;
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
  font-size: 22px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ primary }) => (primary ? "white" : "#333")};
  background: ${({ primary }) =>
    primary
      ? "linear-gradient( to right top, #fa3242, #f8294c, #f42055, #f0185e, #eb1266)"
      : "white"};
  font-weight: 700;
  opacity: ${({ disabled, gray }) => (disabled || gray ? "0.5" : 1)};

  @media (max-width: ${MOBILE_SMALL_MAX}) {
    font-size: 18px;
  }

  &:hover {
    opacity: ${({ disabled, gray }) => (disabled || gray ? "0.5" : "0.9")};
    background: ${({ primary }) =>
      primary
        ? "linear-gradient( to right top, #fa3242, #f8294c, #f42055, #f0185e, #eb1266)"
        : "#f5f5f5"};
  }
`;

export default Button;
