import React from "react";
import * as dg from "dis-gui";

export default ({
  x,
  setX,
  y,
  setY,
  rad,
  setRad,
  showT,
  showB,
  showR,
  showL,
  setShowT,
  setShowB,
  setShowR,
  setShowL,
  colT,
  colB,
  colR,
  colL,
  setColT,
  setColB,
  setColR,
  setColL,
  op,
  setOp,
  space,
  setSpace
}) => {
  return (
    <dg.GUI style={{ zIndex: 5, bottom: "0px" }}>
      <dg.Number
        label="X"
        value={x}
        min={-500}
        max={500}
        step={1}
        onChange={setX}
      />
      <dg.Number
        label="Y"
        value={y}
        min={-500}
        max={500}
        step={1}
        onChange={setY}
      />
      <dg.Number
        label="Diameter"
        value={rad}
        min={0}
        max={1000}
        step={1}
        onChange={setRad}
      />
      <dg.Number
        label="Space"
        value={space}
        min={0}
        max={1000}
        step={1}
        onChange={setSpace}
      />
      <dg.Number
        label="Opacity"
        value={op}
        min={0}
        max={1}
        step={0.1}
        onChange={setOp}
      />
      <dg.Color
        label="Top"
        red={parseInt(colT.split(",")[0], 10)}
        green={parseInt(colT.split(",")[1], 10)}
        blue={parseInt(colT.split(",")[2], 10)}
        onChange={({ red, green, blue }) => setColT(`${red},${green},${blue}`)}
      />
      <dg.Color
        label="Left"
        red={parseInt(colL.split(",")[0], 10)}
        green={parseInt(colL.split(",")[1], 10)}
        blue={parseInt(colL.split(",")[2], 10)}
        onChange={({ red, green, blue }) => setColL(`${red},${green},${blue}`)}
      />
      <dg.Color
        label="Bottom"
        red={parseInt(colB.split(",")[0], 10)}
        green={parseInt(colB.split(",")[1], 10)}
        blue={parseInt(colB.split(",")[2], 10)}
        onChange={({ red, green, blue }) => setColB(`${red},${green},${blue}`)}
      />
      <dg.Color
        label="Right"
        red={parseInt(colR.split(",")[0], 10)}
        green={parseInt(colR.split(",")[1], 10)}
        blue={parseInt(colR.split(",")[2], 10)}
        onChange={({ red, green, blue }) => setColR(`${red},${green},${blue}`)}
      />
      <dg.Checkbox
        label="Top"
        checked={showT}
        onChange={() => setShowT(!showT)}
      />
      <dg.Checkbox
        label="Left"
        checked={showL}
        onChange={() => setShowL(!showL)}
      />
      <dg.Checkbox
        label="Bottom"
        checked={showB}
        onChange={() => setShowB(!showB)}
      />
      <dg.Checkbox
        label="Right"
        checked={showR}
        onChange={() => setShowR(!showR)}
      />
    </dg.GUI>
  );
};
