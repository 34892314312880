import * as React from "react";
import styled from "@emotion/styled";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Version from "./common/Version";
import Header from "./common/Header";

import RouterAnalytics from "./RouterAnalytics";

import Main from "./landing/Main";
import AboutIkigai from "./landing/AboutIkigai";
import Intro from "./landing/Intro";
import Done from "./landing/Done";

import Level1 from "./editing/Level1";
import Level2 from "./editing/Level2";
import Level3 from "./editing/Level3";
import Level4 from "./editing/Level4";
import Playground from "./editing/Playground";
import Progress from "./editing/Progress";
import Download from "./editing/Download";
import BrainstormBox from "./editing/BrainstormBox";
import Confirm from "./landing/Confirm";
import Landing from "./landing/Landing";

const Wrap = styled("div")`
  position: relative;
  overflow: hidden;
  min-height: 100vh;
`;

// Before:
// <Router basename="/ikigai">

export default () => {
  return (
    <Router basename="/">
      <Wrap>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route>
            <Header />
            <BrainstormBox />
            <Route exact path="/confirm" component={Confirm} />
            <Route exact path="/intro" component={Intro} />
            <Route path="/level1" component={Level1} />
            <Route path="/level2" component={Level2} />
            <Route path="/level3" component={Level3} />
            <Route path="/level4" component={Level4} />
            <Route exact path="/playground" component={Playground} />
            <Route exact path="/progress" component={Progress} />
            <Route exact path="/download" component={Download} />
            <Route exact path="/done" component={Done} />
            <Route exact path="/about-ikigai" component={AboutIkigai} />
            <Route exact path="/version" component={Version} />
            <Route exact path="/main" component={Main} />
          </Route>
        </Switch>
      </Wrap>
      <RouterAnalytics />
    </Router>
  );
};
