import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import { signupPackage } from "../api/requests";

import { gtag } from "../analytics";

import FaIcon from "../common/FaIcon";
import Button from "../common/Button";
import Modal from "../common/Modal";

import EmailSubmit from "./EmailSubmit";

const Ul = styled("ul")`
  margin: 30px 0;
  padding-left: 40px;
`;

const Li = styled("li")`
  margin: 10px 0;
  font-size: 20px;
  color: #333;
`;

const Btn = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const SxIcon = styled(FaIcon)`
  margin-right: 20px;
  font-size: 65px;
  padding: 10px 10px;
  width: 80px !important;
  border-radius: 10px;
  color: white;
  background: linear-gradient(
    to right top,
    #fa3242,
    #f8294c,
    #f42055,
    #f0185e,
    #eb1266
  );
`;

const Headline = styled("span")`
  display: flex;
  align-items: center;
`;

const Success = styled("h2")`
  margin: 20px auto;
  font-size: 24px;
  max-width: 400px;
  color: #666;
`;
const Error = styled("p")`
  margin: 0;
  font-size: 16px;
  color: red;
`;

export default withTranslation()(({ t, onClose }) => {
  const bulletPoints = t("package.bulletPoints", { returnObjects: true });

  const [step, setStep] = React.useState(0);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  async function onSubmit(email, vals, brainstormBoxVal) {
    try {
      await signupPackage(email, vals, brainstormBoxVal);

      setError(false);
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
  }

  return (
    <Modal
      headline={
        <Headline>
          <SxIcon icon="gift" /> {t("package.headline")}
        </Headline>
      }
      onClose={onClose}
    >
      {step === 1 && (
        <>
          {!success && (
            <EmailSubmit onBack={() => setStep(0)} onSubmit={onSubmit} />
          )}
          {!!success && <Success>{t("expert.success")}</Success>}
          {!!error && <Error>{t("error.generic")}</Error>}
        </>
      )}
      {step === 0 && (
        <>
          <Ul>
            {bulletPoints.map((bullet, i) => (
              <Li key={i}>{bullet}</Li>
            ))}
          </Ul>
          <Btn
            primary
            onClick={() => {
              gtag("event", "package-buy");
              setStep(1);
            }}
          >
            {t("package.submit")}
          </Btn>
        </>
      )}
    </Modal>
  );
});
