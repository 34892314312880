import * as React from "react";
import styled from "@emotion/styled";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Hotkeys from "react-hot-keys";

import { gtag } from "../analytics";

import Button from "../common/Button";
import Frame from "../common/Frame";
import FaIcon from "../common/FaIcon";
import { MediaMinTablet } from "../common/MediaQuery";

import Flower from "./Flower";

import { sanitize } from "../util";
import { TABLET_MAX } from "../settings";

import ExpertModal from "./ExpertModal";
import PackageModal from "./PackageModal";

const SxFrame = styled(Frame)`
  @media print {
    transform: scale(0.7);
    height: 297mm;
    width: 210mm;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`;

const Txt = styled("p")`
  margin: 8px 0;
  line-height: 1;
`;

const Spread = styled("div")`
  display: grid;
  grid-template: "back ben1 ben2 print" / auto 1fr 1fr auto;
  margin: 15px 0 0;
  width: 100%;

  @media (max-width: ${TABLET_MAX}) {
    grid-gap: 10px;
    grid-template: "back print" "ben1 ben1" "ben2 ben2" / 1fr 1fr;
  }

  @media print {
    display: none;
  }
`;

const SxButton = styled(Button)`
  margin: 0 5px;
  @media (max-width: ${TABLET_MAX}) {
    margin: 0;
  }
`;

const BackButton = styled(SxButton)`
  grid-area: back;
  @media (max-width: ${TABLET_MAX}) {
    width: 100%;
  }
`;

const PrintButton = styled(SxButton)`
  grid-area: print;
  @media (max-width: ${TABLET_MAX}) {
    width: 100%;
  }
`;

const BenefitBtn = styled(SxButton)`
  display: flex;
  align-items: center;
  text-align: left;
`;

const BenefitBtn1 = styled(BenefitBtn)`
  grid-area: ben1;
`;
const BenefitBtn2 = styled(BenefitBtn)`
  grid-area: ben2;
`;

const BigFaIcon = styled(FaIcon)`
  font-size: 50px;
  margin-right: 20px;
`;

const Scrollable = styled("div")`
  @media (max-width: ${TABLET_MAX}) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
  @media print {
    width: initial;
  }
`;

function renderTxts(txt, i) {
  return <Txt key={txt + i}>{sanitize(txt)}</Txt>;
}

const getConfig = vals => ({
  mCanvas: { w: 1100, h: 1100 },
  mRad: 700,
  mSpace: 190,
  download: true,
  bLabels: true,
  bLabelsExpand: true,
  a1Text: vals.slice(0, 3).map(renderTxts),
  a2Text: vals.slice(3, 6).map(renderTxts),
  a3Text: vals.slice(6, 9).map(renderTxts),
  a4Text: vals.slice(9, 12).map(renderTxts),
  b1Text: vals[12],
  b2Text: vals[13],
  b3Text: vals[14],
  b4Text: vals[15],
  c1Text: vals[16],
  c2Text: vals[17],
  c3Text: vals[18],
  c4Text: vals[19],
  dText: vals[20]
});

export default withTranslation()(
  withRouter(
    connect(st => ({
      vals: st.vals
    }))(({ t, history, vals }) => {
      const [packageModalOpen, setPackageModalOpen] = React.useState(false);
      const [expertModalOpen, setExpertModalOpen] = React.useState(false);

      const config = getConfig(vals);

      const isDone = vals.filter(v => !!v).length > 20;

      return (
        <SxFrame>
          {!packageModalOpen && !expertModalOpen && (
            <Hotkeys
              keyName="escape"
              onKeyDown={() => {
                history.goBack();
              }}
            />
          )}
          {packageModalOpen && (
            <PackageModal onClose={() => setPackageModalOpen(false)} />
          )}
          {expertModalOpen && (
            <ExpertModal onClose={() => setExpertModalOpen(false)} />
          )}
          <Spread>
            <BackButton onClick={() => history.goBack()}>
              <FaIcon icon="arrow-left" />{" "}
              <MediaMinTablet>{t("back")}</MediaMinTablet>
            </BackButton>
            {isDone && (
              <>
                <BenefitBtn1
                  onClick={() => {
                    gtag("event", "expert-clicked");
                    setExpertModalOpen(true);
                  }}
                >
                  <BigFaIcon icon="user-graduate" /> {t("expert.headline")}
                </BenefitBtn1>
                <BenefitBtn2
                  onClick={() => {
                    gtag("event", "package-clicked");
                    setPackageModalOpen(true);
                  }}
                >
                  <BigFaIcon icon="gift" /> {t("package.headline")}
                </BenefitBtn2>
              </>
            )}
            <PrintButton
              primary
              onClick={() => {
                gtag("event", "print-clicked");
                window.print();
              }}
            >
              <FaIcon primary icon="download" /> {t("print")}
            </PrintButton>
          </Spread>
          <Scrollable id="div-to-pdf">
            <Flower {...config} />
          </Scrollable>
        </SxFrame>
      );
    })
  )
);
