import * as React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

import Headline from "../common/Headline";
import Input from "../common/Input";
import Frame from "../common/Frame";
import FaIcon from "../common/FaIcon";
import Markdown from "../common/Markdown";
import Button from "../common/Button";

import { postIkigai } from "../api/requests";

import { POST_ITS_COUNT, CIRCLES_COUNT } from "../config";

import Flower from "./Flower";
import ProgressBar from "./ProgressBar";
import LevelConnect from "./LevelConnect";

const MAX_STEPS = POST_ITS_COUNT * CIRCLES_COUNT;

const SxFrame = styled(Frame)`
  max-width: 400px;
  margin: 0 auto;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SxHeadline = styled(Headline)`
  margin: 0;
  text-align: left;
`;

const Sub = styled("h2")`
  margin: 5px 0 0;
  color: #888;
  font-size: 20px;
  line-height: 1.1;
`;

const Group = styled("div")`
  width: 100%;
`;

const SxButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

const Questions = styled("div")`
  font-weight: 700;
  margin: 10px 0;
  font-size: 18px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
`;

const Q = styled("p")`
  margin: 15px 0;
  color: #666;
  line-height: 1.3;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SxFlower = styled(Flower)`
  position: absolute;
  z-index: -1;
  top: 0;
`;

const ReferenceFlower = styled(Flower)`
  flex-shrink: 0;
  margin-left: 5px;
`;

const PaddedTop = styled("div")`
  padding: 20px 0;
  width: 100%;
`;

const Intro = styled(Q)`
  font-weight: 400;
`;

const Prompt = styled(Q)`
  margin-top: 20px;
`;

const Link = styled("p")`
  cursor: pointer;
  font-size: 16px;
  margin: 5px auto 18px;
  font-weight: 700;
  text-align: center;
  color: #666;

  &:hover {
    opacity: 0.9;
  }
`;

const Underlined = styled("span")`
  border-bottom: 2px solid #666;
`;

const SxIcon = styled(FaIcon)`
  color: #666;
`;

const getConfig = (circle, isIntro) => {
  if (isIntro) {
    return {
      mx: 0,
      my: 50,
      mSpace: 180,
      mRad: 250,
      S: 12
    };
  }

  switch (circle) {
    case 0:
      return { mx: 0, my: 300 };
    case 1:
      return { mx: 300, my: 0 };
    case 2:
      return { mx: 0, my: -300 };
    case 3:
      return { mx: -300, my: 0 };
    default:
      console.error("Shouldn't happen, not a valid circle in level 1");
      return {};
  }
};

export default LevelConnect(
  withTranslation()(
    ({ t, history, location, vals, setVals, openBrainstormBox }) => {
      const params = new URLSearchParams(location.search);
      const step = parseInt(params.get("s"));

      const [val, setVal] = React.useState(vals[step] || "");

      const circle = Math.floor(step / POST_ITS_COUNT);
      const isIntro = location.pathname.includes("intro");
      const config = getConfig(circle, isIntro);
      const questions =
        t(`flower.A${circle + 1}.questions`, { returnObjects: true }) || [];
      const text =
        circle < CIRCLES_COUNT
          ? t(`flower.A${circle + 1}.name`, { returnObjects: true })
          : t(`youDidIt`);

      React.useEffect(() => {
        setVal(vals[step] || "");
      }, [step, vals]);

      return (
        <SxFrame>
          <Switch>
            <Route path="/level1/intro">
              <PaddedTop>
                <SxHeadline>Level 1</SxHeadline>
                <Sub>{t(`flower.A.sub`)}</Sub>
              </PaddedTop>
              <Questions>
                <Intro>
                  <Markdown text={t("flower.A.description")} />
                </Intro>
                <Prompt>
                  <Markdown text={t("flower.A.prompt")} />
                </Prompt>
              </Questions>
              <SxButton
                onClick={() => {
                  const nextParams = new URLSearchParams(location.search);

                  history.push(`/level1?${nextParams}`);
                }}
              >
                {t("letsgo")}
              </SxButton>
            </Route>
            <Route>
              <Row>
                <div>
                  <SxHeadline>{text}</SxHeadline>
                  <Sub>{t(`flower.A${circle + 1}.description`)}</Sub>
                </div>
                <ReferenceFlower
                  mSpace={30}
                  mRad={60}
                  noLabels
                  mCanvas={{ w: 125, h: 125 }}
                  mBorder={3}
                  mPad={10}
                  a1Text={
                    step > POST_ITS_COUNT - 1
                      ? 3
                      : step > 0
                      ? step % POST_ITS_COUNT
                      : ""
                  }
                  a2Text={
                    step > POST_ITS_COUNT * 2 - 1
                      ? 3
                      : step > POST_ITS_COUNT
                      ? step % POST_ITS_COUNT
                      : ""
                  }
                  a3Text={
                    step > POST_ITS_COUNT * 3 - 1
                      ? 3
                      : step > POST_ITS_COUNT * 2
                      ? step % POST_ITS_COUNT
                      : ""
                  }
                  a4Text={
                    step > POST_ITS_COUNT * 4 - 1
                      ? 3
                      : step > POST_ITS_COUNT * 3
                      ? step % POST_ITS_COUNT
                      : ""
                  }
                />
              </Row>
              <ProgressBar value={step} max={MAX_STEPS} />
              <Questions>
                {questions instanceof Array &&
                  questions.map(q => <Q key={q}>{q}</Q>)}
              </Questions>
              <Group>
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    setVals(val, step);
                    setVal("");

                    const nextStep = step + 1;
                    const nextParams = new URLSearchParams(location.search);

                    if (nextStep >= MAX_STEPS) {
                      postIkigai(params.get("n"), vals);

                      nextParams.set("l", 1);

                      history.push(`/progress?${nextParams.toString()}`);
                    } else {
                      nextParams.set("s", nextStep);

                      history.push(`/level1?${nextParams}`);
                    }
                  }}
                >
                  <Link onClick={openBrainstormBox}>
                    <SxIcon icon="edit" />{" "}
                    <Underlined>{t("brainstormBox.prompt")}</Underlined>
                  </Link>
                  <Input
                    placeholder={`${t("postIt")}`}
                    value={val}
                    onChange={e => setVal(e.target.value)}
                  />
                  <SxButton primary type="submit" disabled={!val}>
                    {t("next")} <FaIcon primary icon="arrow-right" />
                  </SxButton>
                </form>
              </Group>
            </Route>
          </Switch>
          <SxFlower mSpace={380} mRad={500} {...config} noLabels />
        </SxFrame>
      );
    }
  )
);
