import * as React from "react";
import { withTranslation } from "react-i18next";

import { postConfirm } from "../api/requests";
import Loading from "../common/Loading";
import Frame from "../common/Frame";

export default withTranslation()(({ t, location }) => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    async function confirm() {
      const params = new URLSearchParams(location.search);

      setLoading(true);

      try {
        await postConfirm(params.get("t"));

        setSuccess(true);
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    }

    confirm();
  }, [location.search]);

  return (
    <Frame>
      {loading && <Loading />}
      {error && <p>{t("error.generic")}</p>}
      {success && (
        <>
          <p>{t("confirm.thanks")}</p>
          <p>{t("confirm.contactYou")}</p>
        </>
      )}
    </Frame>
  );
});
