import { connect } from "react-redux";

export default Component =>
  connect(
    st => ({ vals: st.vals }),
    dp => ({
      openBrainstormBox: () => dp({ type: "openBrainstormBox" }),
      setVals: (data, idx) =>
        dp({
          type: "setVal",
          idx,
          data
        })
    })
  )(Component);
