import * as React from "react";
import styled from "@emotion/styled";

const Container = styled("div")`
  width: 100%;
  height: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  margin: 10px 0;
`;

const Bar = styled("div")`
  height: 100%;
  background-color: ${({ done }) => (done ? "#ccc" : "gray")};
  border-radius: 3px;
  transition: width 0.2s ease;
  width: ${({ size }) => `${size}%`};
`;

export default ({ max, value }: PropsType) => {
  return (
    <Container>
      <Bar done={!max} size={max ? ((value - 0) / (max - 0)) * 100 : "100"} />
    </Container>
  );
};
