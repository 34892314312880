import { ENV } from "../config";

const { API_BASE_URL } = ENV;

export default function(
  url: string,
  request?: any, // TODO: Type this
  rawBody: boolean = false
): Promise<any> {
  return fetchJson(`${API_BASE_URL}${url}`, request, rawBody);
}

async function fetchJson(url: string, request?: any, rawBody: boolean = false) {
  const finalRequest = request
    ? {
        ...request,
        body: rawBody ? request.body : JSON.stringify(request.body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...request.headers
        }
      }
    : {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      };

  try {
    const res = await fetch(url, finalRequest);

    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    return Promise.reject(res);
  } catch (e) {
    console.error(e);
  }
}
