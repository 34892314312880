import * as React from "react";
import { withRouter } from "react-router-dom";

import { gtag, GAID } from "./analytics";

export default withRouter(({ location, history }) => {
  React.useEffect(() => {
    if (history.action !== "PUSH") return;

    gtag("config", GAID, {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname
    });
  }, [location, history]);

  return null;
});
