import * as React from "react";
import styled from "@emotion/styled";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Button from "../common/Button";
import Frame from "../common/Frame";
import FaIcon from "../common/FaIcon";
import Markdown from "../common/Markdown";

import Flower from "./Flower";

import { isLevelDisabled, sanitize, getNext } from "../util";

import {
  DESKTOP_MIN,
  DESKTOP_MAX,
  MOBILE_MAX,
  MOBILE_SMALL_MAX
} from "../settings";

const SxFrame = styled(Frame)`
  max-width: 1400px;
  margin: 0 auto;
`;

const Txt = styled("p")`
  margin: 8px 0;
  line-height: 1;
`;

const Spread = styled("div")`
  display: grid;
  grid-gap: 20px;
  grid-template: "notice overview next" / 6fr 2fr 2fr;
  margin: 15px 0 0;
  width: 100%;

  @media (max-width: ${DESKTOP_MAX}) {
    grid-template: "notice notice" "overview next";
  }
`;

const Tabs = styled("div")`
  grid-area: tabs;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-self: end;

  @media (max-width: ${DESKTOP_MAX}) {
    margin: 10px auto 0;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    align-items: center;
  }
`;

const SxButton = styled(Button)`
  flex-shrink: 0;
`;

const Tab = styled(Button)`
  border-left: ${({ selected }) =>
    selected ? "4px solid red" : "4px solid transparent"};
  @media (min-width: ${DESKTOP_MIN}) {
    width: 100%;
    margin: 5px 0;
  }

  @media (max-width: ${DESKTOP_MAX}) {
    border-left: 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: ${({ selected }) =>
      selected ? "3px solid red" : "3px solid transparent"};
  }
  @media (max-width: ${MOBILE_MAX}) {
    font-size: 15px;
    padding: 8px 5px;
  }
  @media (max-width: ${MOBILE_SMALL_MAX}) {
    font-size: 14px;
    padding: 8px 4px;
  }
`;

const OverviewBtn = styled(SxButton)`
  grid-area: overview;
`;

const NextBtn = styled(SxButton)`
  grid-area: next;
`;

const TinyLink = styled("a")`
  display: block;
  margin: 10px auto 0;
  color: #666;
`;

const FlowerContainer = styled("div")`
  grid-area: flower;

  @media (max-width: ${DESKTOP_MAX}) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const Welcome = styled("div")`
  width: 100%;
  grid-area: notice;
`;

const Sub = styled("p")`
  margin: 5px 0 0;
  font-size: 18px;
  color: #333;
`;

const Headline = styled("h3")`
  font-size: 20px;
  line-height: 1.2;
  color: #666;
  margin: 0px;
  font-weight: 900;
`;

const Main = styled("div")`
  display: grid;
  grid-gap: 20px;
  margin-top: 10px;
  grid-template: "tabs flower" 1fr "hint flower" 1fr / 2fr 5fr;
  width: 100%;

  @media (max-width: ${DESKTOP_MAX}) {
    overflow: hidden;
    grid-template: "tabs" "flower" "hint";
  }
`;

const Hint = styled("p")`
  grid-area: hint;
  color: #666;
  margin: 0 0 30px;
`;

const SxFlower = styled(Flower)`
  margin: 0 auto;
`;

function renderTxts(txt, i) {
  return <Txt key={txt + i}>{sanitize(txt)}</Txt>;
}

const getConfig = vals => [
  {
    mCanvas: { w: 900, h: 750 },
    mRad: 380,
    mSpace: 160,
    download: true,
    a1Text: vals.slice(0, 3).map(renderTxts),
    a2Text: vals.slice(3, 6).map(renderTxts),
    a3Text: vals.slice(6, 9).map(renderTxts),
    a4Text: vals.slice(9, 12).map(renderTxts)
  },
  {
    mCanvas: { w: 900, h: 750 },
    mRad: 420,
    mSpace: 160,
    download: true,
    bLabels: true,
    b1Text: vals[12],
    b2Text: vals[13],
    b3Text: vals[14],
    b4Text: vals[15]
  },
  {
    mCanvas: { w: 900, h: 750 },
    mRad: 550,
    mSpace: 150,
    download: true,
    noLabels: true,
    bLabels: true,
    bLabelsExpand: true,
    c1Text: vals[16],
    c2Text: vals[17],
    c3Text: vals[18],
    c4Text: vals[19]
  },
  {
    mCanvas: { w: 900, h: 750 },
    mRad: 600,
    mSpace: 140,
    download: true,
    dText: vals[20],
    noLabels: true
  }
];

export default withTranslation()(
  withRouter(
    connect(st => ({
      vals: st.vals
    }))(({ t, history, location, vals }) => {
      const params = new URLSearchParams(location.search);
      const level = parseInt(params.get("l"));

      const [l, setL] = React.useState(level || 1);

      const config = getConfig(vals)[l - 1];

      const len = vals.filter(v => !!v).length;

      const isDone = len === vals.length;
      const isEmpty = len === 0;

      const onReset = e => {
        e.preventDefault();

        if (window.confirm(t("areYouSure"))) {
          window.localStorage.removeItem("redux");
          window.location.reload();
        }
      };

      const onContinue = () => {
        const { nextStep, nextLevel } = getNext(vals);

        params.set("s", nextStep);

        history.push(`/level${nextLevel}/intro?${params.toString()}`);
      };

      const onDone = () => {
        history.push(`/download`);
      };

      return (
        <SxFrame>
          <Spread>
            <Welcome>
              {isDone ? (
                <>
                  <Headline>{t("result.done")}</Headline>
                  <Sub>{t("result.doneSub")}</Sub>
                </>
              ) : (
                <>
                  <Headline>{t("result.welcome")}</Headline>
                  <Sub>{t("result.welcomeSub")}</Sub>
                </>
              )}
            </Welcome>
            {!isEmpty && (
              <OverviewBtn onClick={() => history.push("/download")}>
                <FaIcon icon="expand" /> {t("overview")}
              </OverviewBtn>
            )}
            <NextBtn primary onClick={isDone ? onDone : onContinue}>
              {isEmpty ? t("letsgo") : isDone ? t("done") : t("next")}{" "}
              <FaIcon primary icon="arrow-right" />
            </NextBtn>
          </Spread>
          <Main>
            <Tabs>
              {[1, 2, 3, 4].map(lev => {
                const disabled = isLevelDisabled(lev, vals);

                return (
                  <Tab
                    key={lev}
                    disabled={disabled}
                    selected={l === lev}
                    onClick={() => setL(lev)}
                  >
                    {disabled && <FaIcon icon="lock" />} Level {lev}
                  </Tab>
                );
              })}
            </Tabs>
            <Hint>
              <Markdown text={t("result.hint")} />
              {!isEmpty && (
                <TinyLink href="" onClick={onReset}>
                  <FaIcon icon="undo" /> {t("resetAll")}
                </TinyLink>
              )}
            </Hint>
            <FlowerContainer>
              <SxFlower {...config} />
            </FlowerContainer>
          </Main>
        </SxFrame>
      );
    })
  )
);
