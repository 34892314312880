import * as React from "react";
import styled from "@emotion/styled";

const Root = styled("div")`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
`;

export default ({ children, className }) => (
  <Root className={className}>{children}</Root>
);
