import * as React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import Modal from "../common/Modal";
import { MOBILE_MIN } from "../settings";

const TextArea = styled("textarea")`
  border-radius: 10px;
  width: 100%;
  outline-width: 0;
  border: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  font-size: 16px;
  -webkit-appearance: none;
  @media (min-width: ${MOBILE_MIN}) {
    min-width: 500px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
`;

const Sub = styled("p")`
  margin: 5px 0 5px;
  font-size: 16px;
  font-weight: 400;
  max-width: 500px;
  line-height: 1.3;
`;

export default connect(
  st => ({ isOpen: st.brainstormBox.isOpen, val: st.brainstormBox.val }),
  dp => ({
    onChange: val => dp({ type: "setBrainstormBoxVal", val }),
    onClose: () => dp({ type: "closeBrainstormBox" })
  })
)(
  withTranslation()(({ t, isOpen, val, onClose, onChange }) => {
    if (!isOpen) return null;

    return (
      <Modal
        onClose={onClose}
        headline={
          <>
            {t("brainstormBox.headline")}
            <Sub>{t("brainstormBox.sub")}</Sub>
          </>
        }
      >
        <TextArea
          rows={18}
          value={val}
          onChange={e => onChange(e.target.value)}
          placeholder={t("brainstormBox.placeholder")}
        />
      </Modal>
    );
  })
);
