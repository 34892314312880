import * as React from "react";
import { compose, createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import persistState from "redux-localstorage";

import { TOTAL_COUNT } from "./config";

import AppRouter from "./AppRouter";

const vals = (st = new Array(TOTAL_COUNT).fill(null), ac) => {
  switch (ac.type) {
    case "setVal":
      const newState = [
        ...st.slice(0, ac.idx),
        ac.data,
        ...st.slice(ac.idx + 1)
      ];

      return newState;
    default:
      return st;
  }
};

const brainstormBox = (st = { val: "", isOpen: false }, ac) => {
  switch (ac.type) {
    case "openBrainstormBox":
      return { ...st, isOpen: true };
    case "closeBrainstormBox":
      return { ...st, isOpen: false };
    case "setBrainstormBoxVal":
      return { ...st, val: ac.val };
    default:
      return st;
  }
};

const reducers = combineReducers({
  vals,
  brainstormBox
});

const enhancer = compose(persistState());

const store = createStore(reducers, enhancer);

export default () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};
