import styled from "@emotion/styled";
import Button from "./Button";

export default styled(Button)`
  padding: 3px 10px;
  box-shadow: none;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
`;
