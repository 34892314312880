// @flow

import * as React from "react";
import styled from "@emotion/styled";
import Hotkeys from "react-hot-keys";

import FaIcon from "./FaIcon";
import TransparentButton from "./TransparentButton";
import OutsideClickContainer from "./OutsideClickContainer";

import { MOBILE_MAX } from "../settings";

const Root = styled("div")`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
`;

const Content = styled("div")`
  display: inline-block;
  text-align: left;
  cursor: initial;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px;
  margin: 0 20px;
  position: relative;

  @media (max-width: ${MOBILE_MAX}) {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
`;

const TopRow = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const Headline = styled("h3")`
  font-size: 24px;
  margin: 0 20px 0 0;
  color: #444;
  line-height: 1.1;
  @media (max-width: ${MOBILE_MAX}) {
    font-size: 20px;
  }
`;

// A modal with three ways to close it
// - a button
// - click outside
// - press esc
const Modal = ({
  className,
  children,
  headline,
  tabIndex,
  onClose
}: PropsT) => {
  return (
    <Root className={className}>
      <Hotkeys keyName="escape" onKeyDown={onClose} />
      <OutsideClickContainer onClickOutside={onClose}>
        <Content>
          <TopRow>
            <Headline>{headline}</Headline>
            <TransparentButton small tabIndex={tabIndex || 0} onClick={onClose}>
              <FaIcon icon="times" />
            </TransparentButton>
          </TopRow>
          {children}
        </Content>
      </OutsideClickContainer>
    </Root>
  );
};

export default Modal;
