import * as React from "react";
import styled from "@emotion/styled";

import { MOBILE_MAX } from "../settings";

const Headline = styled("h1")`
  font-size: 30px;
  line-height: 1.3;
  font-weight: 900;

  @media (max-width: ${MOBILE_MAX}) {
    font-size: 24px;
  }

  margin-bottom: 20px;
  text-align: center;
  color: #666;
  display: inline-block;
  border-bottom: 5px solid #666;
`;

export default ({ children, className }) => {
  return <Headline className={className}>{children}</Headline>;
};
