if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || [];
}

export function gtag() {
  if (typeof window !== "undefined") {
    window.dataLayer.push(arguments);
  }
}

export const GAID = "UA-56063275-10";

gtag("js", new Date());


