import * as React from "react";
import styled from "@emotion/styled";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import { POST_ITS_COUNT } from "../config";

import Controls from "./Controls";

// const LCOL = "23,199,160";
// const TCOL = "255,244,29";
// const RCOL = "124,36,204";
// const BCOL = "255,116,29";

// const LCOL = "177,239,53";
// const BCOL = "42,134,165";
// const RCOL = "255,159,57";
// const TCOL = "208,46,138";

const TCOL = "255,255,0";
const LCOL = "110,255,110";
const BCOL = "110,110,255";
const RCOL = "255,110,110";

const Canvas = styled("div")`
  width: ${({ canvas }) => canvas.w}px;
  height: ${({ canvas }) => canvas.h}px;
  overflow: hidden;
`;

const All = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  transform: ${({ x, y }) => `translate(${x}px,${y}px)`};
`;

const Circle = styled("div")`
  position: absolute;
  z-index: 2;
  width: ${({ r }) => r}px;
  height: ${({ r }) => r}px;
  left: ${({ r, canvas }) => canvas.w / 2 - r / 2}px;
  top: ${({ r, canvas }) => canvas.h / 2 - r / 2}px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  font-size: ${({ FS }) => (FS ? `${FS}px` : "12px")};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  transition: all 0.2s;
  border: ${({ border }) => border}px solid white;
  mix-blend-mode: screen;
  padding: ${({ pad }) => pad}px;
  text-align: center;
`;

const Label = styled("p")`
  color: #666;
  border-bottom: ${({ download }) => (download ? "3px solid #888" : "none")};
  margin: 0 0 5px;
  cursor: pointer;
  font-size: ${({ FS }) => (FS ? `${FS}px` : "16px")};

  &:hover {
    border-bottom: ${({ download }) => (download ? "3px solid #444" : "none")};
  }
`;

const TCircle = styled(Circle)`
  transform: translateY(-${({ space }) => space}px);
  background-color: rgba(${({ RGB }) => RGB}, ${({ OP }) => OP});
  align-items: center;
  justify-content: flex-start;
`;
const LCircle = styled(Circle)`
  transform: translateX(-${({ space }) => space}px);
  background-color: rgba(${({ RGB }) => RGB}, ${({ OP }) => OP});
  align-items: flex-start;
  padding: ${({ r, pad, download }) =>
    download ? `0 ${r / 2 + pad * 4}px 0 ${pad}px` : `${pad}px`};
  text-align: left;
  justify-content: center;
`;
const RCircle = styled(Circle)`
  transform: translateX(${({ space }) => space}px);
  background-color: rgba(${({ RGB }) => RGB}, ${({ OP }) => OP});
  padding: ${({ r, pad, download }) =>
    download ? `0 ${pad}px 0 ${r / 2 + pad * 4}px` : `${pad}px`};
  text-align: right;
  align-items: flex-end;
  justify-content: center;
`;
const BCircle = styled(Circle)`
  transform: translateY(${({ space }) => space}px);
  background-color: rgba(${({ RGB }) => RGB}, ${({ OP }) => OP});
  align-items: center;
  justify-content: flex-end;
`;

const Int = styled("div")`
  position: absolute;
  z-index: 2;
  width: ${({ r }) => r / 2}px;
  height: ${({ r }) => r / 2}px;
  left: ${({ r, canvas }) => canvas.w / 2 - r / 4}px;
  top: ${({ r, canvas }) => canvas.h / 2 - r / 4}px;
  ${"" /* background-color: rgba(0, 0, 0, 0.05);
  mix-blend-mode: screen; */}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${({ FS }) => (FS ? `${FS}px` : "12px")};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${({ r }) => r / 4 - 80}px;
  color: #666;
  transition: all 0.2s;
`;
const Int1 = styled(Int)`
  transform: ${({ r, factor }) =>
    `translate(-${((r / 2) * factor) / 2}px, -${((r / 2) * factor) / 2}px)`};
`;
const Int2 = styled(Int)`
  transform: ${({ r, factor }) =>
    `translate(-${((r / 2) * factor) / 2}px, ${((r / 2) * factor) / 2}px)`};
`;
const Int3 = styled(Int)`
  transform: ${({ r, factor }) =>
    `translate(${((r / 2) * factor) / 2}px, ${((r / 2) * factor) / 2}px)`};
`;
const Int4 = styled(Int)`
  transform: ${({ r, factor }) =>
    `translate(${((r / 2) * factor) / 2}px, -${((r / 2) * factor) / 2}px)`};
`;

const CBase = styled("div")`
  position: absolute;
  z-index: 3;
  width: ${({ r }) => r / 2}px;
  height: ${({ r }) => r / 2}px;
  left: ${({ r, canvas }) => canvas.w / 2 - r / 4}px;
  top: ${({ r, canvas }) => canvas.h / 2 - r / 4}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${({ FS }) => (FS ? `${FS}px` : "12px")};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${({ r }) => r / 4 - 80}px;
  color: #666;
  transition: all 0.2s;
`;

const C1 = styled(CBase)`
  transform: ${({ r, factor }) => `translate(0, -${(r * factor) / 4}px)`};
`;
const C2 = styled(CBase)`
  transform: ${({ r, factor }) => `translate(-${(r * factor) / 4}px, 0)`};
`;
const C3 = styled(CBase)`
  transform: ${({ r, factor }) => `translate(0, ${(r * factor) / 4}px)`};
`;
const C4 = styled(CBase)`
  transform: ${({ r, factor }) => `translate(${(r * factor) / 4}px, 0)`};
`;

const D = styled("div")`
  position: absolute;
  z-index: 3;
  width: ${({ r }) => r}px;
  height: ${({ r }) => r}px;
  left: ${({ r, canvas }) => canvas.w / 2 - r / 2}px;
  top: ${({ r, canvas }) => canvas.h / 2 - r / 2}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${({ S }) => (S ? `${S}px` : "16px")};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  padding: ${({ r }) => r / 2 - 100}px;
  color: #666;
  transition: all 0.2s;
`;

function notEmpty(num) {
  return typeof num !== "undefined" && num !== null;
}

export default withTranslation()(
  withRouter(
    ({
      t,
      history,
      location,
      className,
      controls,
      mCanvas,
      mSpace,
      mRad,
      my,
      mx,
      mBorder,
      noLabels,
      a1Text,
      a2Text,
      a3Text,
      a4Text,
      b1Text,
      b2Text,
      b3Text,
      b4Text,
      c1Text,
      c2Text,
      c3Text,
      c4Text,
      dText,
      noDLabel,
      mPad,
      FS,
      labelFS,
      download,
      mShowB,
      mShowT,
      mShowL,
      mShowR,
      bLabels,
      bLabelsExpand
    }) => {
      const [x, setX] = React.useState(mx || 0);
      const [y, setY] = React.useState(my || 0);
      const [rad, setRad] = React.useState(mRad || 300);
      const [space, setSpace] = React.useState(mSpace || 110);
      const [op, setOp] = React.useState(0.5);
      const [colT, setColT] = React.useState(TCOL);
      const [colR, setColR] = React.useState(RCOL);
      const [colL, setColL] = React.useState(LCOL);
      const [colB, setColB] = React.useState(BCOL);
      const [showT, setShowT] = React.useState(true);
      const [showR, setShowR] = React.useState(true);
      const [showL, setShowL] = React.useState(true);
      const [showB, setShowB] = React.useState(true);

      const border = notEmpty(mBorder) ? mBorder : 7;
      const pad = notEmpty(mPad) ? mPad : 30;
      const canvas = notEmpty(mCanvas) ? mCanvas : { w: 800, h: 700 };

      React.useEffect(() => {
        if (notEmpty(mShowL)) setShowL(mShowL);
      }, [mShowL]);
      React.useEffect(() => {
        if (notEmpty(mShowT)) setShowT(mShowT);
      }, [mShowT]);
      React.useEffect(() => {
        if (notEmpty(mShowR)) setShowR(mShowR);
      }, [mShowR]);
      React.useEffect(() => {
        if (notEmpty(mShowB)) setShowB(mShowB);
      }, [mShowB]);
      React.useEffect(() => {
        if (notEmpty(mx)) setX(mx);
      }, [mx]);
      React.useEffect(() => {
        if (notEmpty(my)) setY(my);
      }, [my]);
      React.useEffect(() => {
        if (notEmpty(mSpace)) setSpace(mSpace);
      }, [mSpace]);
      React.useEffect(() => {
        if (notEmpty(mRad)) setRad(mRad);
      }, [mRad]);

      function goto(to, step) {
        const params = new URLSearchParams(location.search);

        params.set("s", step);

        if (download) {
          history.push(`${to}?${params}`);
        }
      }

      return (
        <>
          {!!controls && (
            <Controls
              x={x}
              setX={setX}
              y={y}
              setY={setY}
              rad={rad}
              setRad={setRad}
              space={space}
              setSpace={setSpace}
              op={op}
              setOp={setOp}
              colT={colT}
              colR={colR}
              colB={colB}
              colL={colL}
              setColT={setColT}
              setColR={setColR}
              setColB={setColB}
              setColL={setColL}
              showT={showT}
              showB={showB}
              showL={showL}
              showR={showR}
              setShowT={setShowT}
              setShowB={setShowB}
              setShowL={setShowL}
              setShowR={setShowR}
            />
          )}
          <Canvas className={className} canvas={canvas}>
            <All x={x} y={y}>
              {showT && (
                <TCircle
                  OP={op}
                  RGB={colT}
                  r={rad}
                  space={space}
                  canvas={canvas}
                  border={border}
                  pad={pad}
                  download={download}
                >
                  {!noLabels && (
                    <Label
                      FS={labelFS}
                      onClick={() => goto(`/level1`, 0 * POST_ITS_COUNT)}
                      download={download}
                    >
                      {t("flower.A1.name")}
                    </Label>
                  )}
                  {a1Text && <span>{a1Text}</span>}
                </TCircle>
              )}
              {showL && (
                <LCircle
                  OP={op}
                  FS={FS}
                  RGB={colL}
                  r={rad}
                  space={space}
                  canvas={canvas}
                  border={border}
                  pad={pad}
                  download={download}
                >
                  {!noLabels && (
                    <Label
                      FS={labelFS}
                      onClick={() => goto(`/level1`, 1 * POST_ITS_COUNT)}
                      download={download}
                    >
                      {t("flower.A2.name")}
                    </Label>
                  )}
                  {a2Text && <span>{a2Text}</span>}
                </LCircle>
              )}
              {showB && (
                <BCircle
                  OP={op}
                  FS={FS}
                  RGB={colB}
                  r={rad}
                  space={space}
                  canvas={canvas}
                  border={border}
                  pad={pad}
                  download={download}
                >
                  {!noLabels && (
                    <Label
                      FS={labelFS}
                      onClick={() => goto(`/level1`, 2 * POST_ITS_COUNT)}
                      download={download}
                    >
                      {t("flower.A3.name")}
                    </Label>
                  )}
                  {a3Text && <span>{a3Text}</span>}
                </BCircle>
              )}
              {showR && (
                <RCircle
                  OP={op}
                  FS={FS}
                  RGB={colR}
                  r={rad}
                  space={space}
                  canvas={canvas}
                  border={border}
                  pad={pad}
                  download={download}
                >
                  {!noLabels && (
                    <Label
                      FS={labelFS}
                      onClick={() => goto(`/level1`, 3 * POST_ITS_COUNT)}
                      download={download}
                    >
                      {t("flower.A4.name")}
                    </Label>
                  )}
                  {a4Text && <span>{a4Text}</span>}
                </RCircle>
              )}
              {(!!b1Text || bLabels) && (
                <Int1
                  factor={bLabelsExpand ? 1.4 : 1.2}
                  space={space}
                  r={rad}
                  canvas={canvas}
                >
                  <Label onClick={() => goto(`/level2`, 0)} download={download}>
                    {t("flower.B1.name")}
                  </Label>
                  {b1Text && <span>{b1Text}</span>}
                </Int1>
              )}
              {(!!b2Text || bLabels) && (
                <Int2
                  factor={bLabelsExpand ? 1.4 : 1.2}
                  space={space}
                  r={rad}
                  canvas={canvas}
                >
                  <Label onClick={() => goto(`/level2`, 1)} download={download}>
                    {t("flower.B2.name")}
                  </Label>
                  {b2Text && <span>{b2Text}</span>}
                </Int2>
              )}
              {(!!b3Text || bLabels) && (
                <Int3
                  factor={bLabelsExpand ? 1.4 : 1.2}
                  space={space}
                  r={rad}
                  canvas={canvas}
                >
                  <Label onClick={() => goto(`/level2`, 2)} download={download}>
                    {t("flower.B3.name")}
                  </Label>
                  {b3Text && <span>{b3Text}</span>}
                </Int3>
              )}
              {(!!b4Text || bLabels) && (
                <Int4
                  factor={bLabelsExpand ? 1.4 : 1.2}
                  space={space}
                  r={rad}
                  canvas={canvas}
                >
                  <Label onClick={() => goto(`/level2`, 3)} download={download}>
                    {t("flower.B4.name")}
                  </Label>
                  {b4Text && <span>{b4Text}</span>}
                </Int4>
              )}
              {!!c1Text && (
                <C1 factor={1.2} space={space} r={rad} canvas={canvas}>
                  <Label onClick={() => goto(`/level3`, 0)} download={download}>
                    {t("flower.C1.name")}
                  </Label>
                  {c1Text}
                </C1>
              )}
              {!!c2Text && (
                <C2 factor={1.2} space={space} r={rad} canvas={canvas}>
                  <Label onClick={() => goto(`/level3`, 1)} download={download}>
                    {t("flower.C2.name")}
                  </Label>
                  {c2Text}
                </C2>
              )}
              {!!c3Text && (
                <C3 factor={1.2} space={space} r={rad} canvas={canvas}>
                  <Label onClick={() => goto(`/level3`, 2)} download={download}>
                    {t("flower.C3.name")}
                  </Label>
                  {c3Text}
                </C3>
              )}
              {!!c4Text && (
                <C4 factor={1.2} space={space} r={rad} canvas={canvas}>
                  <Label onClick={() => goto(`/level3`, 3)} download={download}>
                    {t("flower.C4.name")}
                  </Label>
                  {c4Text}
                </C4>
              )}
              {!!dText && (
                <D space={space} r={rad} canvas={canvas}>
                  {!noDLabel && (
                    <Label
                      onClick={() => goto(`/level4`, 0)}
                      download={download}
                    >
                      {t("flower.D.name")}
                    </Label>
                  )}
                  {dText}
                </D>
              )}
            </All>
          </Canvas>
        </>
      );
    }
  )
);
