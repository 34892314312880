import React from "react";
import styled from "@emotion/styled";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Label = styled("span")`
  margin-left: 10px;
  font-size: 16px;
  line-height: 1;
`;

const Container = styled("div")`
  flex-shrink: 0;
  position: relative;
  font-size: 22px;
  width: 25px;
  height: 25px;
  border: 3px solid #f42055;
  border-radius: 3px;
  box-sizing: content-box;
`;

const Checkmark = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f42055;

  &:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 0px;
    width: 6px;
    height: 15px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
  }
`;

export default ({ className, label, value, onChange }) => (
  <Row className={className} onClick={() => onChange(!value)}>
    <Container>{value && <Checkmark />}</Container>
    <Label>{label}</Label>
  </Row>
);
