import { sanitize } from "../util";

import fetchJSON from "./fetchJSON";

export function postIkigai(teamname, vals) {
  if (!!teamname) {
    return fetchJSON("/ikigai", {
      method: "POST",
      body: {
        name: sanitize(teamname),
        vals
      }
    });
  }
}

export function signupPackage(email, vals, brainstormBoxVal) {
  return fetchJSON("/ikigai", {
    method: "POST",
    body: {
      email,
      vals,
      brainstormBoxVal,
      package: true
    }
  });
}

export function signupExpert(email, vals, brainstormBoxVal) {
  return fetchJSON("/ikigai", {
    method: "POST",
    body: {
      email,
      vals,
      brainstormBoxVal,
      expert: true
    }
  });
}

export function postConfirm(confirmationToken) {
  return fetchJSON("/ikigai/confirm", {
    method: "POST",
    body: { confirmationToken }
  });
}
