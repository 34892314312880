import styled from "@emotion/styled";
import {
  MOBILE_SMALL_MIN,
  MOBILE_MAX,
  MOBILE_MIN,
  TABLET_MAX,
  TABLET_MIN,
  DESKTOP_MIN
} from "../settings";

export const MediaMinMobile = styled("div")`
  display: none;
  @media (min-width: ${MOBILE_SMALL_MIN}) {
    display: initial;
  }
`;

export const MediaMaxMobile = styled("div")`
  display: none;
  @media (max-width: ${MOBILE_MAX}) {
    display: initial;
  }
`;

export const MediaMaxTablet = styled("div")`
  display: none;
  @media (max-width: ${TABLET_MAX}) {
    display: initial;
  }
`;

export const MediaMinDesktop = styled("div")`
  display: none;
  @media (min-width: ${TABLET_MIN}) {
    display: initial;
  }
`;

export const MediaMinLargeDesktop = styled("div")`
  display: none;
  @media (min-width: ${DESKTOP_MIN}) {
    display: initial;
  }
`;

export const MediaMinTablet = styled("div")`
  display: none;
  @media (min-width: ${MOBILE_MIN}) {
    display: initial;
  }
`;
